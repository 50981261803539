import Modal from 'react-bootstrap/Modal'
import dateFnsFormat from 'date-fns/format';
import React, { useState } from 'react';
import helperUtil from '../../../shared/Util'
import Button from 'react-bootstrap/Button';
import UpdateSharp from '@material-ui/icons/UpdateOutlined';
import { StyleSheet, Text, View } from 'react-native';
import SkipNext from '@material-ui/icons/SkipNext';

export function CopyDayEventsDlg({ showModal, onClose, date, onSubmit}) {
    const [datesArray, setDatesArray] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const closeModal = () => {
        onClose();
    }

    const onShowModal = () => {
        buildForwardCalendarArray(date);    
    }

    const getDlgTitle = () => {
        if (!date) {
            return 'Copy Day Events';
        }
        var lclDate = new Date(date);
        const format = 'MM/dd/yyyy';
        var dateFormatted = dateFnsFormat(lclDate, format);
        return 'Copy Events from ' + dateFormatted;
    }

    const buildForwardCalendarArray = (startDate) => {
        setDatesArray([]);
        var list = []
        var addedDate = new Date(startDate)
        const format = 'dd yyyy';
        for (var i = 0; i < 7; i++) {
            addedDate.setDate(addedDate.getDate() + 1);
            
            var dayOfWeek = helperUtil.getDayOfTheWeek(addedDate);            
            var shortMonth = addedDate.toLocaleString('en-us', { month: 'short' });
            var dateFormatted = dateFnsFormat(addedDate, format);
            var title = dayOfWeek + ' ' + shortMonth + ' ' + dateFormatted;

            const data = {
                title: title,
                date: addedDate,
                checked: false,
                copied: false,
                isError: false
            };

            list.push(data);
            addedDate = new Date(addedDate);
        }
        setDatesArray(list);        
    }
    
    const onCopyDateChecked = (copyDate, index) => {
        copyDate.checked = !copyDate.checked;
        if(!copyDate.checked) {
            copyDate.copied = false;
            copyDate.isError = false;
        }
        forceUpdate();
    }
    
    const renderDate = (date, index) => {
        return (            
            <div className="left-section">
                <input style={{ marginLeft: 10 }} type="checkbox"
                    checked={date.checked}
                    onChange={() => onCopyDateChecked(date)} />
                <label style={{ marginLeft: 10, color: date.copied ? 'green' : date.isError ? 'red' : 'black'  }} htmlFor={`custom-checkbox-${index}`}>{date.title}</label>
            </div>                        
        );
    }

    const renderDates = () => {
        return (<div>{ datesArray.map(renderDate) }</div>);
    }

    const handleSubmitClick = () => {
        for (let i = 0; i < datesArray.length; i++) {
            datesArray[i].copied = false;
            datesArray[i].isError = false;
        }

        var dates = datesArray.filter((d) => d.checked === true);
        onSubmit(dates);        
    }

    const next7DaysClick = () => {
        var startDate = datesArray[datesArray.length - 1].date;
        buildForwardCalendarArray(startDate);
    }

    const isSubmitValid = () => {
        var date = datesArray.find((d) => d.checked === true);
        return (date);
    }

    const styles = StyleSheet.create({
        text: {
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'bold',
            letterSpacing: 0.2,
            color: 'white',
        }
    });

    const renderButtons = () => {
        if (!helperUtil.IsMobile()) {
            return (
                <View style={{ flex: 0.95, marginTop: 10, justifyContent: "flex-end", flexDirection: "row" }}>
                    <View style={{ flex: 0.3, justifyContent: "flex-end", flexDirection: "row" }}>
                        <Button onClick={() => next7DaysClick()}>
                            <Text style={styles.text}>Next 7 days</Text>
                        </Button>
                    </View>
                    <View style={{ flex: 0.3, justifyContent: "flex-end", flexDirection: "row" }}>
                        <Button onClick={() => handleSubmitClick()} disabled={!isSubmitValid()}>
                            <Text style={styles.text}>Submit</Text>
                        </Button>
                    </View>
                </View>
            );
                
        }

        return (
                <View style={{ flex: 0.95, marginTop: 10, justifyContent: "flex-end", flexDirection: "row" }}>
                    <View style={{ flex: 0.3, justifyContent: "flex-end", flexDirection: "row" }}>
                        <button className="link-btn" onClick={() => next7DaysClick()}>
                            <SkipNext />
                        </button>
                    </View>
                    <View style={{ flex: 0.3, justifyContent: "flex-end", flexDirection: "row" }}>
                        <button className="link-btn" onClick={() => handleSubmitClick()} disabled={!isSubmitValid()}>
                            <UpdateSharp />
                        </button>
                    </View>
                </View>
            );
    }

    return (
        <section>
            <Modal show={showModal} onHide={closeModal} onShow={onShowModal}>
                <Modal.Header closeButton style={{ borderBottom: "none", borderTop: "none" }}>
                    <Modal.Title>
                        {getDlgTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(150vh - 210px)', overflowY: 'auto' }}>
                    {renderDates()}
                    {renderButtons()}
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default CopyDayEventsDlg;