import React, { useState } from 'react';
import { View, Text } from 'react-native';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format';
import helperUtil from '../../../shared/Util'
import moment from 'moment'
import Button from 'react-bootstrap/Button';
import donationService from '../../../shared/services/donationService'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import { visuallyHidden } from '@material-ui/utils';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { alpha } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Paper from '@material-ui/core//Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';

function createData(id, name, amount, purpose, transactioDate, fee) {
    return {
      id,
      name,
      amount,
      purpose,
      transactioDate,
      fee,
    };
  }
  
  function descendingStringComparator(a, b, orderBy) {
    if(a === undefined || !a){
      return 0;
    }

    if (b.toUpperCase() < a.toUpperCase()) {
      return -1;
    }
    if (b.toUpperCase() > a.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingNumberComparator(a, b) {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    switch(orderBy) {
      case "transactionAmount":
      case "fee":
        return order === 'desc'
            ? (a, b) => descendingNumberComparator(Number(a.amount), Number(b.amount))
            : (a, b) => -descendingNumberComparator(Number(a.amount), Number(b.amount));
      case "transactioDate":
        return order === 'desc'
            ? (a, b) => descendingNumberComparator(Number(a.id), Number(b.id))
            : (a, b) => -descendingNumberComparator(Number(a.id), Number(b.id));
      case "purpose":
        return order === 'desc'
            ? (a, b) => descendingStringComparator(a.purpose, b.purpose)
            : (a, b) => -descendingStringComparator(a.purpose, b.purpose);
      default:
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }    
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'transactionAmount',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'purpose',
      numeric: true,
      disablePadding: false,
      label: 'Purpose',
    },
    {
      id: 'transactioDate',
      numeric: true,
      disablePadding: false,
      label: 'Trans Time',
    },
    {
      id: 'fee',
      numeric: true,
      disablePadding: false,
      label: 'Fee',
    },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell 
          padding={'normal'}
          aligalign="left" style={{ width: "0%" }}>

          </TableCell>
          {headCells.map((headCell) => (
            <TableCell 
              key={headCell.id}
              align="left"
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Transactions
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  

function PayPalAdmin() {
    const [startTransactionsDate, setTransactionsStartDate] = useState(new Date());
    const [endTransactionsDate, setTransactionsEndDate] = useState(new Date());
    const [isGettingTransactions, setIsGettingTransactions] = useState(false);
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [rows, setRows] = useState([]);
    const [balance, setBalance] = useState('');
    
    React.useEffect(() => {
        async function fetchData() {
            populateInitialDate();
            getPayPalBalances();
        }
        fetchData();
        
    }, []);

    const renderTitle = () => {
        return (
            <View style={{ flex: 1, justifyContent: "center", alignItems: 'center' }}>
                <Text style={{ fontWeight: "bold", fontFamily: 'Roboto', marginBottom: 10}}>Pay Pal Admin</Text>                
            </View>
          );
    }
    
    const getPayPalBalances = async () => {
      let balance = await donationService.getPayPalBalances();
      console.log(balance.availableBalance);
      setBalance(balance.availableBalance);
    }

    const populateInitialDate = () => {
        let initialDate = new Date((new Date()).getFullYear(), 0, 1);
        setTransactionsStartDate(initialDate);
    }

    const renderStartTransactionsDate = () => {
        return (
                <div>
                    <View style={{ flex: 0.5, justifyContent: "center", alignItems: 'center', flexDirection: 'row', backgroundColor: '#F4F6F7', zIndex: 2 }}>
                    <View style={{ flex: 0.25, justifyContent: "flex-end", alignItems: "flex-end", zIndex: 2}}>
                        <Text style={{ marginBottom: 6, alignItems: "flex-end" }}>Start Date: </Text>
                    </View>
                    <View style={{ flex: 0.25, justifyContent: "flex-start", alignItems: "flex-start"}}>
                        {renderStartTransactionsDateCalendar()}
                    </View>
                    </View>
                </div>                                
          );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        console.log(property)
      };
    
      const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = rows.map((n) => n.id);
          setSelected(newSelected);
          return;
        }
        setSelected([]);
      };
    
      const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const isSelected = (id) => selected.indexOf(id) !== -1;
    
      // Avoid a layout jump when reaching the last page with empty rows.
      const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    
      const visibleRows = React.useMemo(
        () =>
          stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          ),
        [order, orderBy, page, rowsPerPage, rows],
      );

      
    const renderTransactions = () => {
        return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                            >
                                <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={visibleRows.length}
                                />
                                 <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell style={{width: '20px'}}>
                      
                    </TableCell>
                    <TableCell
                      style={{ width: "15%" }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left"  style={{ width: "15%" }}>{row.amount}</TableCell>
                    <TableCell align="left"  style={{ width: "15%" }}>{row.purpose}</TableCell>
                    <TableCell align="left"  style={{ width: "15%" }}>{row.transactioDate}</TableCell>
                    <TableCell align="left"  style={{ width: "15%" }}>{row.fee}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (33) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>      
            </Box>
        );
    }

    const renderCurrentBalance = () => {
      if(!balance || balance.length === 0){
        return;        
      }

      return (
        <div>
          <View style={{ flex: 1, justifyContent: "flex-start", alignItems: 'center', flexDirection: 'row', backgroundColor: '#F4F6F7', marginLeft: 10 }}>
                <Text style={{ marginBottom: 6, alignItems: "flex-end" }}>Balance: </Text>                
                <Text style={{ marginBottom: 6, alignItems: "flex-end" }}>${balance}</Text>                
          </View>
        </div>
      );
    }

    const renderEndTransactionsDate = () => {
        return (
            <div>
                <View style={{ flex: 0.5, justifyContent: "center", alignItems: 'center', flexDirection: 'row', backgroundColor: '#F4F6F7', zIndex: 1 }}>
                <View style={{ flex: 0.25, justifyContent: "flex-end", alignItems: "flex-end", zIndex: 1}}>
                    <Text style={{ marginBottom: 6, alignItems: "flex-end" }}>End Date: </Text>
                </View>
                <View style={{ flex: 0.25, justifyContent: "flex-start", alignItems: "flex-start"}}>
                    {renderEndTransactionsDateCalendar()}
                </View>
                </View>
            </div>                                
      );
    }

    const renderStartTransactionsDateCalendar = () => {
        const FORMAT = 'MM/dd/yyyy';
        if(!startTransactionsDate){
            return;
        }

        return <DayPickerInput
        formatDate={helperUtil.formatDate}
        parseDate={helperUtil.parseDate}
        format={FORMAT}
        placeholder={dateFnsFormat(startTransactionsDate, FORMAT)}
        dayPickerProps={{
            month: startTransactionsDate,
            showWeekNumbers: true,
            todayButton: 'Today',
        }} 
        onDayChange={
            day => {
                if(moment.isDate(day)){
                  setTransactionsStartDate(day)
                }          
            }
        } />;    
    }

    const renderEndTransactionsDateCalendar = () => {
        const FORMAT = 'MM/dd/yyyy';
        
        return <DayPickerInput
        formatDate={helperUtil.formatDate}
        parseDate={helperUtil.parseDate}
        format={FORMAT}
        placeholder={dateFnsFormat(endTransactionsDate, FORMAT)}
        dayPickerProps={{
            month: endTransactionsDate,
            showWeekNumbers: true,
            todayButton: 'Today',
        }} 
        onDayChange={
            day => {
                if(moment.isDate(day)){
                    setTransactionsEndDate(day)
                }          
            }
        } />;    
    }

    const handleGetPayPalTransactionsClick = async event => {
        event.preventDefault();
        setIsGettingTransactions(true);
        
        setRows([]);
        console.log(visibleRows.length);

        const arr = [];

        let transactions = await donationService.getPayPalTransations(startTransactionsDate, endTransactionsDate);
        console.log(transactions);
        setIsGettingTransactions(false);

        for (var i = 0; i < transactions.length; i++) {             
          arr.push(createData(transactions[i].id, 
                transactions[i].payerFullName, 
                transactions[i].transactionAmount, 
                transactions[i].transactionSubject, 
                transactions[i].transactionInitiationDate, 
                transactions[i].feeAmount));            
        }

        setRows(arr);

        console.log(arr);
        setOrderBy('id'); 
    }

    const renderFilterButton = () => {
        if(!isGettingTransactions){
            return (
                <View style={{ flex: 0.5, justifyContent: "center", alignItems: 'center', flexDirection: 'row', backgroundColor: '#F4F6F7' }}>
                    <Button style={{ marginTop: 10}} variant="light" onClick={handleGetPayPalTransactionsClick} disabled={isGettingTransactions}>
                        Get Transactions
                    </Button>
                </View>
            );
        }

        return (
            <View style={{ flex: 0.5, justifyContent: "center", alignItems: 'center', flexDirection: 'row', backgroundColor: '#F4F6F7' }}>
                <Button style={{ marginTop: 10}} variant="light" onClick={handleGetPayPalTransactionsClick} disabled={isGettingTransactions}>
                    Please wait
                </Button>
            </View>
        );
    }

    const renderTransactionsDateRangeChooser = () => {
        return(
            <div>
                {renderStartTransactionsDate()}
                {renderEndTransactionsDate()}
            </div>
        );
    }

    return (
        <div>
            {renderTitle()}
            {renderTransactionsDateRangeChooser()}
            {renderCurrentBalance()}
            {renderFilterButton()}
            {renderTransactions()}
        </div>
      );
}

export default PayPalAdmin