import { View, Text } from 'react-native';
import ClassifiedAdPostMobile from './ClassifiedAdPostMobile'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function ClassifiedPostHomeMobile({onSectionChanged, section, editPageId, editMode}) {
    
    const renderCategoryDropdown = () => {
        return(<View style={{flex: 1, justifyContent: "center", alignItems: 'center', zIndex: 1}}>
                <DropdownButton
                    variant="secondary"
                    menuVariant="dark"
                    title="Category"
                    className="mt-2"
                >
                    <Dropdown.Item onClick={() => onSectionChanged('SE')}>Services</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => onSectionChanged('RE')}>Real Estate</Dropdown.Item>
                    <Dropdown.Item onClick={() => onSectionChanged('FS')}>For Sale</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => onSectionChanged('OT')}>Other</Dropdown.Item>
                </DropdownButton>
               </View>
        );
    }

    const renderLinks = () => {
        return renderCategoryDropdown();                    
    }

    const renderTitle = () => {
        let category = '';

        switch (section) {
            case 'RE':
                category = "Real Estate";
                break;
            case 'FS':
                category = "For Sale";
                break;
            case 'SE':
                category = "Services";
                break;
            case 'OT':
                    category = "Other";
                    break;
            default:
                category = "";
                break;            
        }

        if(category.length < 1){
            return(
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                    <Text style={{fontWeight: 'bold', fontSize: 14}}>Post ad</Text>
                </View>
            );
        }

        return(
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                <Text style={{fontWeight: 'bold', fontSize: 14}}>Posting ad in </Text>
                <Text style={{color: 'blue', fontWeight: 'bold', fontSize: 14}}>{category}</Text>
            </View>
        );
    }

    const renderClassifiedAdPost = () => {
        if (section === "RE") {
            return (
                <ClassifiedAdPostMobile 
                key='123'
                editPageId={editPageId}
                editMode={editMode}
                category={section}/>
            );
        }

        if (section === "FS") {
            return (
                <ClassifiedAdPostMobile key='124' 
                                  editPageId={editPageId} 
                                  editMode={editMode}
                                  category={section}/>
            );
        }

        if (section === "SE") {
            return (
                <ClassifiedAdPostMobile key='125'
                                  editPageId={editPageId}
                                  editMode={editMode}
                                  category={section}/>
            );
        }

        if (section === "OT") {
            return (
                <ClassifiedAdPostMobile key='126'
                                  editPageId={editPageId}
                                  editMode={editMode}
                                  category={section}/>
            );
        }
    }

    // main render
    return (
        <div>
            {renderTitle()}
            {renderLinks()}
            {renderClassifiedAdPost()}
        </div>
    );
}

export default ClassifiedPostHomeMobile;
