import React, { useState } from 'react';
import authenticationService from '../../shared/services/authentication.service'
import './MenageRoles.css' 
import Table from 'react-bootstrap/Table'
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Navigate } from "react-router-dom";

function MenageRoles() {
    const [roles, setRoles] = useState([]);
    const [isAddNewRole, setIsAddNewRole] = useState(false);
    const [newRole, setNewRole] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    
    React.useEffect(() => {

        async function fetchRoles() {
            let result = await authenticationService.getAllRoles();
            console.log(result);
            setRoles(result);
        }
        
        fetchRoles();
    }, []);

    const renderRole  = (role, index) => {
        return (
          <tr key={index}>
            <td class="nav-item link_cursor" onClick={()=>handleRole(role.id)}>{role.id}</td>
            <td class="nav-item link_cursor" onClick={()=>handleRole(role.id)}>{role.name}</td>
            <td>
                <button class="link-btn" onClick={()=>handleDelete(role.id, role.name)}>
                    <Delete/>
                </button>
            </td>
          </tr>
        )
    }

    const handleRole = (id) => {
        console.log("looking for " + id)
        setSelectedRole(id);
        console.log('Selected role ');
        console.log( selectedRole);
    }

    const renderUsersInRole = () => {
        if(!selectedRole){
            return;
        }
        console.log('Trying to redirect to usersInRole');
        return <Navigate to={{
            pathname: "/usersInRole",
            state: { selectedRole: selectedRole }
          }} />;
    }

    const deleteRole = async(id) => {
        console.log("deleting role " + id)
        let result = await authenticationService.deleteRole(id);
        console.log(result);
        window.location.reload();
    }

    const handleDelete = async(id, name) => {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure to delete ' + name + '?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {deleteRole(id)}
              },
              {
                label: 'No',
                onClick: () => {console.log('no deletion called`')}
              }
            ]
          });        
    }

    const handleAddRole = () => {
        setIsAddNewRole(true);
    }

    const renderAddRole = () => {
        if(!isAddNewRole)
        {
            return;
        }

        return (
            <div>
                <input name="newRole" 
                 placeholder="Enter New Role (required)" 
                 onChange={handleNewRoleChange} value={newRole || ''}/>
                 <p/>
                 <div class="left">
                    <Button onClick={handleSubmit} disabled = {!isFormValidated()}>Submit</Button>
                </div>
            </div>
        )
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        let result = await authenticationService.addRole(newRole);
        console.log(result);
        window.location.reload();
    }

    const isFormValidated = () => {
        return newRole;
    }

    const handleNewRoleChange = async(event) => {
        let newRole = event.target.value;
        setNewRole(newRole);        
    }

    const renderMainPage = (id) => {
        if(!authenticationService.isAdministrator()){
            return <Navigate to="" />;
        }
    }  

    return (
        <div className="wrapper">
            {renderMainPage()}
            <h3>Roles</h3>
            <Table striped hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>
                        <button class="link-btn" onClick={handleAddRole}>
                            <AddCircle/>
                        </button>          
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map(renderRole)}
                </tbody>
            </Table>
            {renderAddRole()}
            {renderUsersInRole()}
        </div>
      )
}

export default MenageRoles