import { View, SafeAreaView, StyleSheet, TextInput, Text } from 'react-native';
import React, { useState, useCallback } from 'react';
import helperUtil from '../../../shared/Util'
import Button from 'react-bootstrap/Button';
import UpdateSharp from '@material-ui/icons/UpdateOutlined';
import schedulerService from '../../../shared/services/schedulerService'

export const weekScheduler = {
    WeekScheduler
};

export function WeekScheduler({date}) {
    const [text, onChangeText] = React.useState("");
    const [isHTML, setIsHTML] = useState(false);

    const styles = StyleSheet.create({
        input: {
          height: 300,
          marginLeft: 10,
          borderWidth: 1,
          padding: 10,
        },
        text: {
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'bold',
            letterSpacing: 0.2,
            color: 'white',
        }
    });

    const getWeekly = useCallback(async () => {
        try {
                let range = helperUtil.getWeekRangeFormatted(date, true);
                let startDate = range[0];
                let endDate = range[1];
                let weeklyEvent = await schedulerService.getWeeklyEvent(startDate, endDate);
                if(weeklyEvent){
                    onChangeText(weeklyEvent.description)
                    console.log('weeklyEvent.isHTML')
                    setIsHTML(weeklyEvent.isHTML)                    
                    return;
                }

                onChangeText(' ')
                setIsHTML(false)    
        }
        catch(e) {
            onChangeText(' ')
            setIsHTML(false)
        }
    }, [date])

    React.useEffect(() => {
        async function fetchData() {
            await getWeekly()            
        }
        fetchData();
        
    }, [getWeekly]);

    const renderRange = () => {
        let range = helperUtil.getWeekRangeFormatted(date, false);
        console.log(range)
        let startDay = range[0];
        let endDay = range[1];

        return(
            <View style={{flex: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center', marginBottom: 10 }}>
                <View style={{marginTop: 10, flexDirection: "row", justifyContent: "center", alignItems: 'center'}}>
                    <Text>Week of: </Text>
                    <Text style={{fontWeight: 'bold'}}>{startDay} - {endDay}</Text>
                </View>
            </View>
        );
    }

    const handleUpdateClick = async () => {
        let range = helperUtil.getWeekRangeFormatted(date, false);
        let startDate = range[0];
        let endDate = range[1];
        await schedulerService.updateWeekEvent(text, startDate, endDate, isHTML);
        await getWeekly()
    }

    const handleIsHTML = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setIsHTML(value);
    };

    const renderUpdateBtn = () => {
        if(!helperUtil.IsMobile()){
            return <Button onClick={()=>handleUpdateClick()}>
                        <Text style={styles.text}>Update</Text>
                    </Button>
        }

        return <button className="link-btn" onClick={()=>handleUpdateClick()}>
                    <UpdateSharp/>                        
                </button>
    }

    return (
        <div>
            {renderRange()}
            <View style={{flexDirection: "row",marginBottom: 10, flex: 1}}>
                <View style={{ flex: 0.95, justifyContent: "flex-end", alignItems: 'center', flexDirection: "row", marginRight: 0}}>
                    {renderUpdateBtn()}                    
                    <label style={{ marginLeft: 10}} >
                        <input type="checkbox" 
                        checked={isHTML}
                        onChange={handleIsHTML}/>
                        HTML
                    </label>
                </View>
            </View>

            <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{flex: 0.95}}>
                    <SafeAreaView>
                        <TextInput
                            multiline="true"
                            editable
                            style={styles.input}
                            onChangeText={onChangeText}
                            placeholder="Enter the day's text here"
                            value={text}
                        />                        
                        </SafeAreaView>
                    </View>
                </View>
        </div>
    );
}

export default weekScheduler;