import React, { useState } from 'react';
import authenticationService from '../../shared/services/authentication.service'
import './MenageRoles.css' 
import Table from 'react-bootstrap/Table'
import 'react-confirm-alert/src/react-confirm-alert.css';

function UsersInRoles(props) {
    //console.log(props.location.state.selectedRole);
    const [users, setUsers] = useState([]);
    let roleId = !props.location.state ? ' ' : props.location.state.selectedRole;
    
    React.useEffect(() => {
        if(!authenticationService.isUserLoggedIn()){
            return;
        }

        async function fetchUsers() {
            let result = await authenticationService.getUsersInRole(roleId);
            console.log(result);
            setUsers(result);
        }
        
        fetchUsers();
    }, [roleId]);

    const renderUser  = (user, index) => {
        return (
          <tr key={index}>
            <td style={{ textAlign: 'left' }}>{index + 1}</td>
            <td style={{ textAlign: 'left' }}>{user.userName}</td>
            <td style={{ textAlign: 'left' }}>{user.email}</td>
          </tr>
        )
    }

    return (        
        <div className="wrapper">
            <h3>Users</h3>
            <Table striped hover size="sm">
                <thead>
                    <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(renderUser)}
                </tbody>
            </Table>
        </div>
      )
}

export default UsersInRoles