import React, { useState, useCallback } from 'react';
import '../Home.css';
import hebcalAPIservice from '../../shared/services/hebcalAPIservice'
import { Text, View } from 'react-native';
import TextField from "@material-ui/core/TextField";
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import dateFnsFormat from 'date-fns/format';
import helperUtil from '../../shared/Util'
import schedulerService from '../../shared/services/schedulerService'

function ShuleScheduleCalendar() {
    const [gregorianDate, setGregorianDate] = useState(new Date());
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [weeklyEvent, setWeeklyEvent] = useState();

    const transformCalendarEvents = useCallback(async (scheduleEvents) => {
        setCalendarEvents(null);
        if(scheduleEvents === null || scheduleEvents === undefined || scheduleEvents.days === undefined || scheduleEvents.days === null){
            console.log('scheduleEvents unacceptible');            
            return;
        }

        setCalendarEvents(scheduleEvents.days);
        
    }, [])

    const transformWeeklyEvent = useCallback(async (weeklyEvent) => {
        if(!weeklyEvent){
            console.log('weeklyEvent unacceptible');
            setWeeklyEvent(null);
            return;
        }

        setWeeklyEvent(weeklyEvent);
        
    }, [])

    React.useEffect(() => {

        async function fetchWeeklySchedule() {
            try {
                setLoading(true);
                let scheduleEvents = await hebcalAPIservice.getWeeklySchedule(formatDayDate(gregorianDate));            
                transformCalendarEvents(scheduleEvents);
                let range = helperUtil.getWeekRangeFormatted(gregorianDate, true);
                let startDate = range[0];
                let endDate = range[1];
                let weekEvent = await schedulerService.getWeeklyEvent(startDate, endDate);
                transformWeeklyEvent(weekEvent);
                setLoading(false);
            }
            catch(e) {
                
            }
        }

        setWeeklyEvent(null);
        fetchWeeklySchedule();
    }, [gregorianDate, transformCalendarEvents, transformWeeklyEvent]);

    const formatDayDate = (date) => {
        if(!date){
            return '';
        }
        var lclDate = new Date(date);
        const format = 'MM-dd-yyyy';
        return dateFnsFormat(lclDate, format);
    }

    const handleDateChange = async (date) => {
        setGregorianDate(date);                
    };

    const renderEvent = (calendarEvent, index) => {
        if(calendarEvent.isAllDay && (calendarEvent.description === null || calendarEvent.description === undefined || calendarEvent.description.length === 0)){
            return (
                <div>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text style={{textAlign: 'left', backgroundColor: "#F2F4F4"}}>{calendarEvent.title}</Text>
                    </View>                    
                </View>
                <p/>
                </div>
              );
        }

        if(calendarEvent.isAllDay && (calendarEvent.description !== null || calendarEvent.description !== undefined || calendarEvent.description.length > 0)){
            return (
                <div>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text style={{textAlign: 'left', backgroundColor: "#F2F4F4"}}>{calendarEvent.title}</Text>
                    </View>
                </View>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text style={{textAlign: 'left'}}>{calendarEvent.description}</Text>
                    </View>
                </View>            
                <p/>
              </div>
              );
        }

        return (
            <div>                
            <View
                style={{
                    flexDirection: "row"                    
                }}
                >
                <View style={{ flex: 0.3, backgroundColor: "#F2F4F4" }} >
                    <Text style={{textAlign: 'left'}}>{calendarEvent.title}</Text>
                    </View>
                <View style={{ flex: 0.2, backgroundColor: "#F2F4F4" }} >
                <Text style={{textAlign: 'left'}}>{calendarEvent.eventTime}</Text>
                </View>
                <View style={{ flex: 0.5, backgroundColor: "#F2F4F4" }}>
                    <Text style={{textAlign: 'left'}}>{calendarEvent.description}</Text>
                </View>
            </View>
            </div>
          );
    }

    const renderDay = (calendarDay, index) => {
        if(calendarDay === undefined || calendarDay === null || calendarDay.events === undefined || calendarDay.events === null){
            return;
        }

        return (
            <div className='flex-container100'>
                <p/>
                <View style={{flex: 1, flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <Text style={{textAlign: 'left'}}>{calendarDay.date}</Text>
                    </View>
                </View>                
                {calendarDay.events.map(renderEvent)}                    
            </div>
          );
    }

    const renderWeekly = () => {
        console.log('renderWeekly');
        if(!weeklyEvent){
            return;
        }

        if(!weeklyEvent.isHTML){
            return (
                <div className='flex-container100'>
                    <p/>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                        <View style={{flex: 1, justifyContent: "center", alignItems: 'center'}}>
                        <Text style={{textAlign: 'center'}}>{weeklyEvent.description}</Text>
                        </View>
                    </View>                                
                </div>
              );
        }

        return (
            <div className='flex-container100'>
                <p/>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                    <View style={{flex: 1, justifyContent: "center", alignItems: 'center'}}>
                        <div className="content" dangerouslySetInnerHTML={{__html: weeklyEvent.description}}></div>
                    </View>
                </View>                                
            </div>
          );
    }

    const handleAddWeek = async () => {
        let startDate = getNextStartWeekDate();
        setGregorianDate(startDate); 
    }

    const handleSubtractWeek = async () => {
        let endDate = getPreviousStartWeekDate();
        setGregorianDate(endDate); 
    }
    
    const getPreviousStartWeekDate = () => {
        let previousDay = new Date(gregorianDate.getTime());
        let endDate = new Date();

        for(var i = 0; i < 7; i++){
            previousDay.setDate(previousDay.getDate() - 1);
            var day = previousDay.getDay();
            if(day === 5){
                endDate = previousDay;
                break;
            }            
        }

        return endDate;
    }

    const getNextStartWeekDate = () => {
        let nextDay = new Date(gregorianDate.getTime());
        let startDate = new Date();

        for(var i = 0; i < 7; i++){
            nextDay.setDate(nextDay.getDate() + 1);
            var day = nextDay.getDay();
            if(day === 0){
                startDate = nextDay;
                break;
            }            
        }
        
        //console.log(startDate);
        return startDate;
    }

    const renderRange = () => {
        let range = helperUtil.getWeekRange(gregorianDate);
        //console.log(range)
        const FORMAT = 'MM/dd/yyyy';
        let startDay = dateFnsFormat(range[0], FORMAT);
        let endDay = dateFnsFormat(range[1], FORMAT);

        return(
            <View style={{flex: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                <View style={{marginTop: 10, flexDirection: "row", justifyContent: "center", alignItems: 'center'}}>
                    <Text>Week of: </Text>
                    <Text style={{fontWeight: 'bold'}}>{startDay} - {endDay}</Text>
                </View>
            </View>
        );
    }

    const renderCalendarEvents = () => {
        if(!calendarEvents || calendarEvents.length === 0){
            return;
        }

        return (<div className='flex-container98'>
        {calendarEvents.map(renderDay)}                    
        </div>);
    }
    
    const renderHeader = () => {
        return (
            <View style={{flex: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center', marginBottom: 10}}>
                <Text>Use arrows to move between weeks</Text>
            </View>
        );
    }

    const renderGregorianDate = () => {
        return (
            <View style={{flex: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center'}}>
                    <View style={{justifyContent: "flex-end"}}>
                    <button className="link-btn" onClick={()=>handleSubtractWeek()}>
                        <ArrowBackIos/>                        
                    </button>
                    </View>
                    <View style={{background:"#F8F9F9"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        value={gregorianDate}
                        format="MM/dd/yyyy EEEE"
                        onChange={handleDateChange}
                        renderInput={(props) => <TextField {...props} />}
                        />            
                    </LocalizationProvider>
                    </View>
                    <View style={{justifyContent: "flex-start"}}>
                    <button className="link-btn" onClick={()=>handleAddWeek()}>
                        <ArrowForwardIos/>                        
                    </button>
                    </View>
            </View>
        );
    }

    if((!calendarEvents || calendarEvents.length === 0) && (!weeklyEvent || !weeklyEvent.description || weeklyEvent.description.length === 0)){        
            let eventsLoadedText = !loading ? 'No scheduled events found for this week' : 'Loading...';
            return (
                <div className='center'>
                    {renderGregorianDate()}
                    {renderRange()}
                    <p/>
                    <Text>{eventsLoadedText}</Text>
                </div>
            );        
    }

    return (
        <div className='center'>
            {renderHeader()}
            {renderGregorianDate()}
            {renderRange()}
            {renderWeekly()}
            <p/>
            {renderCalendarEvents()}
        </div>
    );
}

export default ShuleScheduleCalendar;