import React, { useState } from 'react';
import classifiedAdService from '../../../shared/services/classifiedAdService'
import { Text, View } from 'react-native';
import PostAdEditor from '../../../shared/Controls/PostAdEditor'
import { EditorState, ContentState } from 'draft-js';
import { convertFromRaw } from 'draft-js';
import CheckCircle from '@material-ui/icons/CheckCircle';
import utilService from '../../../shared/services/utilservice'

function ClassifiedAdsAdmin() {
    const [activeAds, setActiveAds] = useState([]);

    React.useEffect(() => {
        async function fetchData() {
            let ads = await classifiedAdService.fetchClassifiedsForAdmin();
            setActiveAds(ads);
        }
        fetchData();

    }, []);

    const renderPostedAdDescription = (postedAd) => {
        if(!postedAd.description || postedAd.description === undefined || postedAd.description.length === 0){
            return;
        }

        let editorState;
        if(!utilService.isJsonString(postedAd.description)){
            editorState = EditorState.createWithContent(ContentState.createFromText(postedAd.description))
        }
        else{
            const contentState = convertFromRaw(JSON.parse(postedAd.description));
            editorState = EditorState.createWithContent(contentState);
        }

        return (
            <PostAdEditor
                onRichTextEditorStateChanged={null}
                initialEditorState={editorState}
                isReadOnly={true} />
        );
    }
    const handleApproveClick = async (postedAd) => {
        let response = await classifiedAdService.approveAd(postedAd.id);
        
        let updatedAds = 
            activeAds.map(obj => {
                if (obj.id === postedAd.id) {
                    return {...obj, isAdminApproved: response};
                }
        
                return obj;
            });

        setActiveAds(updatedAds)        
    }

    const renderIsEmailConfirmed = (flag) => {
        let value = flag ? "Yes" : "No";

        if(flag){
            return (<View style={{ flex: 0.05 }}>
                        <Text style={{color: 'green'}}>{value}</Text>
                    </View>
            );
        }

        return (<View style={{ flex: 0.05 }}>
            <Text style={{color: 'red'}}>{value}</Text>
        </View>
);
    }

    const renderAdminApproveBtn = (postedAd) => {
        if (postedAd.isError === undefined) {
            let statusColor = postedAd.isAdminApproved ? "success" : "primary";

            return (
                <button className="link-btn" onClick={() => handleApproveClick(postedAd)}>
                    <CheckCircle color={statusColor} />
                </button>
            );
        }

        return (
            <button className="link-btn" onClick={() => handleApproveClick(postedAd)}>
                <CheckCircle color={"error"} />
            </button>
        );
    }

    const renderPostedAd = (postedAd, index) => {
        if (postedAd === undefined || postedAd === null) {
            return;
        }

        return (
            <div className='flex-container100'>
                <p />
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 0.08 }}>
                        <Text>{postedAd.title}</Text>
                    </View>
                    <View style={{ flex: 0.1 }}>
                        <Text>{postedAd.shortDescription}</Text>
                    </View>
                    <View style={{ flex: 0.45, marginRight: 5 }}>
                        {renderPostedAdDescription(postedAd)}
                    </View>
                    <View style={{ flex: 0.08 }}>
                    <Text>{postedAd.telephone}</Text>
                    </View>
                    <View style={{ flex: 0.15, justifyContent: "center", alignItems: 'center' }}>
                        <Text>{postedAd.email}</Text>
                    </View>
                    <View style={{ flex: 0.02 }}>
                        <Text>{postedAd.category}</Text>
                    </View>
                    <View style={{ flex: 0.04 }}>
                        <Text>{postedAd.durationWeeks}</Text>
                    </View>
                    <View style={{ flex: 0.04 }}>
                        <Text>{renderIsEmailConfirmed(postedAd.isEmailConfirmed)}</Text>
                    </View>
                    <View style={{ flex: 0.04 }}>
                        {renderAdminApproveBtn(postedAd)}
                    </View>                    
                </View>
                {renderThumbnails(postedAd)}
            </div>
        );
    }

    const renderThumbnails = (postedAd) => {
        if(!postedAd.thumbnails || postedAd.thumbnails === undefined || postedAd.thumbnails.length === 0){
            return
        }

        var thumbnails = postedAd.thumbnails;
        return (<View style={{flexDirection: 'row'}}>
            {thumbnails.map(renderThumbnailImage)}
            </View>
        );
    }

    const renderThumbnailImage = (thmbnail) => {
        if(!thmbnail || thmbnail === undefined || !thmbnail.image){
            return;
        }

        return(<View style={{marginRight: 10, justifyContent: "center", alignItems: "center"}}>
                <img src={`data:image/jpeg;base64,${thmbnail.image}`} width={100} height={100} alt=''/>                
               </View>
        );
    }

    const renderActieAds = () => {
        if (!activeAds || activeAds.length === 0) {
            return;
        }

        return (<div className='flex-container98'>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                    <View style={{ flex: 0.08, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Title</Text>
                    </View>
                    <View style={{ flex: 0.1, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Short Description</Text>
                    </View>
                    <View style={{ flex: 0.45, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Description</Text>
                    </View>
                    <View style={{ flex: 0.08, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Telephone</Text>
                    </View>
                    <View style={{ flex: 0.15, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Email</Text>
                    </View>
                    <View style={{ flex: 0.02, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Ctg</Text>
                    </View>
                    <View style={{ flex: 0.04, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Dur</Text>
                    </View>
                    <View style={{ flex: 0.04, justifyContent: "center", alignItems: 'center' }}>
                        <Text>Confirmed</Text>
                    </View>
                    <View style={{ flex: 0.04 }}>
                        
                    </View>                    
                </View>
            {activeAds.map(renderPostedAd)}
        </div>);
    }

    return (
        <div>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                <h3>Last 90 days</h3>
            </View>
            {renderActieAds()}
        </div>
    )
}

export default ClassifiedAdsAdmin
