import React from 'react'
import authenticationService from '../../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import AddCircle from '@material-ui/icons/AddCircle';

class MenageUserProjects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName : '',
            email : '',
            isAddNewProject: false
        };
    }

    componentDidMount() {
        console.log(this.props.location.state.user);
        let user = this.props.location.state.user;
        this.setState({
            firstName : user.firstName
        });
    }

    renderWelcomeHeader() {                
        return (
            <div>
                <h2>Menage {this.state.firstName}'s projects</h2>
            </div>
        );
    }

    handleAddProject = () => {
        this.setState({isAddNewProject : true});
        console.log('handleAddProject');
    }

    renderAddNewProject = () => {
        if(!this.state.isAddNewProject)
        {
            return;
        }
console.log('renderAddNewProject');
        return (
            <div>
                Put your code here
            </div>
        )
    }

    render() {
        if(!authenticationService.isUserLoggedIn() || !authenticationService.isAdministrator()){
            return <Navigate to="" />;
        }

        return (
            <section>                
            {this.renderWelcomeHeader()}
            <p/>
            <Table striped hover size="sm">
                <TableHead>
                    <TableRow>
                        <th>Title</th>
                        <th>Description</th>
                        <th>
                        <button className="link-btn" onClick={this.handleAddProject}>
                            <AddCircle/>
                        </button>
                        </th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                </TableBody>
                {this.renderAddNewProject()}
            </Table>
            </section>
        );
    }
}

export default MenageUserProjects;