import React, { useState} from 'react';
import ClassifiedPostHomeDesktop from './ClassifiedPostHomeDesktop';
import ClassifiedPostHomeMobile from './ClassifiedPostHomeMobile';

function ClassifiedPostHome() {
    const [section, setSection] = useState('');
    const [editPageId, setEditPageId] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {        
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    const isMobile = width <= 768;

    window.onload = function (){
        onPageLoad();      
    }

    const onPageLoad = async () => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);
        setEditPageId('');

        if(!parsed || !parsed.token || !parsed.category) {
            return;
        }
        
        console.log('parsed')
        setSection(parsed.category);
        setEditPageId(parsed.token);
        setEditMode(true);
    }

    const onSectionChanged = (value) => {
        setSection(value)
    }

    const renderDesktop = () => {
        return <ClassifiedPostHomeDesktop
                onSectionChanged={onSectionChanged}
                section = {section} 
                editPageId = {editPageId}
                editMode={editMode}
                />
    }

    const renderMobile = () => {
        return <ClassifiedPostHomeMobile
                onSectionChanged={onSectionChanged}
                section = {section} 
                editPageId = {editPageId}
                editMode={editMode}
                />
    }

    const renderEnvironment = () => {
        if(!isMobile){
            return renderDesktop();
        }

        return renderMobile();
    }

    // main render
    return (
        <div>            
            {renderEnvironment()}
        </div>
    );
}

export default ClassifiedPostHome;
