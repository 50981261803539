import { View } from 'react-native';
import RadioBtn from '../../shared/Controls/RadioBtn'
import ClassifiedAdPostDesktop from './ClassifiedAdPostDesktop'

function ClassifiedPostHomeDesktop({onSectionChanged, section, editPageId, editMode}) {
    
    const handleSectionChange = (event, value) => {
        onSectionChanged(value);
    };

    const renderRadioBtns = () => {
        return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                <RadioBtn
                    label="Services"
                    onChange={handleSectionChange}
                    value="SE"
                    checked={section === "SE"}
                />
                <RadioBtn
                    label="Real estate"
                    onChange={handleSectionChange}
                    value="RE"
                    checked={section === "RE"}
                />
                <RadioBtn
                    label="For sale"
                    onChange={handleSectionChange}
                    value="FS"
                    checked={section === "FS"}
                />
                <RadioBtn
                    label="Other"
                    onChange={handleSectionChange}
                    value="OT"
                    checked={section === "OT"}
                />
            </View>
        );
    }

    const renderLinks = () => {
        return renderRadioBtns();                    
    }

    const renderTitle = () => {
        return(
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                <h1>Classified</h1>
            </View>
        );
    }

    const renderClassifiedAdPost = () => {
        if (section === "RE") {
            return (
                <ClassifiedAdPostDesktop 
                key='123'
                editPageId={editPageId}
                editMode={editMode}
                category={section}/>
            );
        }

        if (section === "FS") {
            return (
                <ClassifiedAdPostDesktop key='124' 
                                  editPageId={editPageId} 
                                  editMode={editMode}
                                  category={section}/>
            );
        }

        if (section === "SE") {
            return (
                <ClassifiedAdPostDesktop key='125'
                                  editPageId={editPageId}
                                  editMode={editMode}
                                  category={section}/>
            );
        }

        if (section === "OT") {
            return (
                <ClassifiedAdPostDesktop key='126'
                                  editPageId={editPageId}
                                  editMode={editMode}
                                  category={section}/>
            );
        }
    }

    // main render
    return (
        <div>
            {renderTitle()}
            {renderLinks()}
            {renderClassifiedAdPost()}
        </div>
    );
}

export default ClassifiedPostHomeDesktop;
