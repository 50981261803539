import React, { useState } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { Navigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import classifiedAdService from '../../shared/services/classifiedAdService'
import { convertFromRaw } from 'draft-js';
import { EditorState, ContentState } from 'draft-js';
import PostAdEditor from '../../shared/Controls/PostAdEditor'
import ImageSlider from '../../shared/Controls/ImageSlider'
import Button from 'react-bootstrap/Button';
import ClassifiedsCategoryViewer from "./ClassifiedsCategoryViewer"
import utilService from '../../shared/services/utilservice'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

function ClassifiedsMainViewer() {
    const [category, setCategory] = useState('');
    const [initialAds, setInitialAds] = useState([]);
    const [initialAdID, setInitialAdID] = useState('');
    const [isInitialAdSelected, setIsInitialAdSelected] = useState(false);
    const [isInitialMode, setInitialMode] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        async function fetchData() {
            let ads = await classifiedAdService.getFirstInCategories();
            setInitialAds(ads);
            setIsLoading(false)
        }
        fetchData();

    }, []);

    const handlePostClick = async () => {
        window.location.href = "/classifiedPostHome"
    }

    const handleBackBtnClick = async () => {
        setCategory('');
        window.location.href="/classifiedsMainViewer"
    }
    
    const handleCategorySelection = async (ctg) => {
        console.log('category ' + ctg);
        setCategory(ctg);
        setInitialMode(false);
    }

    const handleSelectedFirstAd = async (ad) => {
        console.log(ad)
        setInitialAdID(ad.id)
        setIsInitialAdSelected(true)
    }

    const getCategory = (ads, ctg) => {
        return ads.filter(ad => { return ad.category === ctg; })
    }

    const renderAdDescription = (ad) => {
        if(!ad || !ad.description || ad.description === undefined || ad.description.length < 3){
            return;
        }

        let editorState;
        if(!utilService.isJsonString(ad.description)){
            editorState = EditorState.createWithContent(ContentState.createFromText(ad.description))
        }
        else{
            const contentState = convertFromRaw(JSON.parse(ad.description));
            editorState = EditorState.createWithContent(contentState);
        }

        return (
            <PostAdEditor
                onRichTextEditorStateChanged={null}
                initialEditorState={editorState}
                isReadOnly={true} />
        );
    }

    const renderInitialCategoryAd = (ctg, displayCategory) => {
        var ads = getCategory(initialAds, ctg);
        if(!ads || ads === undefined || ads.length === 0){
            return;
        }

        return(
            <div>
            <View style={{ flex: .98, 
                           justifyContent: 'flex-start', 
                           marginLeft: 10, 
                           marginRight: 20, 
                           marginTop: 10 }}>
                <TouchableHighlight onPress={() => handleCategorySelection(ads[0].category)}>
                    <Text style={{fontWeight: 'bold', fontSize: 14, backgroundColor: "#CED2EF", padding: 5}}> {displayCategory}</Text>
                </TouchableHighlight>
                <TouchableHighlight onPress={() => handleSelectedFirstAd(ads[0])}>
                    <View style={{ flex: .98, 
                            justifyContent: 'flex-start',                            
                            marginTop: 10}}>
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>{ads[0].title}</Text>
                        <Text style={{fontSize: 14}}>{ads[0].shortDescription}</Text>
                        {renderAdDescription(ads[0])}                        
                    </View>
                </TouchableHighlight>
                {renderThumbnails(ads[0])}
            </View>            
            </div>
        );
    }

    const renderThumbnails = (ad) => {
        return (
            <ImageSlider
                slides={ad.thumbnails}
            />
        );
    }

    const renderIsLoading = () => {
        if(!isInitialMode || !isLoading){
            return;
        }

        return(<View style={{ flex: .98, 
            justifyContent: 'flex-start', 
            marginLeft: 10, 
            marginRight: 20, 
            marginTop: 10 }}>
                <Text style={{fontWeight: 'bold', fontSize: 16}}>Loading. Please wait...</Text>
            </View>

        );
    }

    const renderInitialAds = () => {
        if(!isInitialMode || !initialAds || initialAds === undefined || initialAds.length === 0){
            return;
        }

        return (
            <div>
                {renderInitialCategoryAd('SE', 'Services')}
                {renderInitialCategoryAd('RE', 'Real Estate')}
                {renderInitialCategoryAd('FS', 'For Sale')}
                {renderInitialCategoryAd('OT', 'Other')}
            </div>

        );        
    }

    const renderBackBtn = () => {
        if(!category || category === undefined || category.length === 0){
            return;
        }

        return(<View style={{flex: .2, justifyContent: "flex-end", alignItems: 'flex-end',marginRight: 20}}>
                    <button onClick={() => handleBackBtnClick()}>
                        <KeyboardBackspace/>
                    </button>
                </View>
        );
    }

    const renderTopLevel = () => {
        return(
            <div>
                <View style={{ flex: 1, 
                    zIndex: 1,
                    flexDirection: "row"}}>
                    {renderCategoryDropdown()}
                    {renderBackBtn()}
                    <View style={{flex: .2, justifyContent: "flex-end", alignItems: 'flex-end',marginRight: 20}}>
                    <Button onClick={() => handlePostClick()}>
                        <Text style={{color: 'white',fontSize: 14, lineHeight: 16, fontWeight: 'bold', letterSpacing: 0.2,}}>Post</Text>
                    </Button>
                    </View>
                </View>
            </div>
        );
    }

    const renderCategoryDropdown = () => {
        return(<View style={{flex: 1, justifyContent: "center", alignItems: 'center'}}>
                <DropdownButton
                    id="dropdown-button-dark-example2"
                    variant="secondary"
                    menuVariant="dark"
                    title="Category"
                    className="mt-2"
                >
                    <Dropdown.Item onClick={() => handleCategorySelection('SE')}>Services</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleCategorySelection('RE')}>Real Estate</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleCategorySelection('FS')}>For Sale</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleCategorySelection('OT')}>Other</Dropdown.Item>
                </DropdownButton>
               </View>
        );
    }

    const renderCategoryViewer = () => {
        if(isInitialMode){
            return;
        }

        return (<ClassifiedsCategoryViewer
            category={category}
        />
        );
    }

    const renderAdContent = () => {
        if(!isInitialMode){
            return;
        }

        if (isInitialAdSelected) {
          return <Navigate
          to={{
            pathname: "/classifiedsAdViewer",
            search: "?ad=" + initialAdID
          }}
        />
        }
    }

    return (
        <div>
            {renderTopLevel()}
            {renderIsLoading()}
            {renderInitialAds()}
            {renderAdContent()}
            {renderCategoryViewer()}
        </div>
    );
}

export default ClassifiedsMainViewer;