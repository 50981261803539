import Modal from 'react-bootstrap/Modal'
import { Text, View, SafeAreaView, StyleSheet, TextInput } from 'react-native';
import React, { useState } from 'react';
import useViewport from '../../../shared/ViewportProvider'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MobileTimePicker from '@material-ui/lab/MobileTimePicker';
import TextField from "@material-ui/core/TextField";
import DesktopTimePicker from '@material-ui/lab/DesktopTimePicker';
import Button from 'react-bootstrap/Button';
import UpdateSharp from '@material-ui/icons/UpdateOutlined';
import dateFnsFormat from 'date-fns/format';

export function DayEventDlg({onClose, showModal, dlgTitle, onDayEventSubmit, eventInfo}) {
    const titlePlaceHolder = "Enter the event title";
    const [eventTime, setEventTime] = useState(new Date('2018-01-01T00:00:00.000Z'));
    const [description, onChangeDescription] = useState('');
    const [title, setTitle] = useState('');

    const closeModal = () => {
        onClose();
    }

    const onShowModal = () => {
        onChangeDescription(eventInfo.description);
        if(eventInfo.action === 'edit') {
            console.log(eventInfo)
            setEventTime(eventInfo.editDate)
            setTitle(eventInfo.title)
            console.log(title)
        }
    }    

    const handleTitleChange = async(event) => {
        let value = event.target.value;
        setTitle(value);        
    }

    const getFullDateTime = () => {
        let eventDate = eventInfo.action === 'add' ? eventInfo.date : eventInfo.editDate;
        console.log(eventInfo)
        console.log(eventInfo.action)
        console.log(eventDate)
        let newDate = 
        new Date(eventDate.getFullYear(), 
                 eventDate.getMonth(), 
                 eventDate.getDate(), 
                 eventTime.getHours(), 
                 eventTime.getMinutes(), 
                 eventTime.getSeconds())        
        return newDate;
    }

    const renderTimeControl = () => {
        if(IsMobile()){
            return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileTimePicker
                    value={eventTime}
                    onChange={(newValue) => {
                        setEventTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            );
        }

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                    value={eventTime}
                    onChange={(newValue) => {
                        setEventTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            );
    }

    const isSubmitValid = () => {
        if(!title || title.length === 0){
            return false;
        }

        return true;
    }

    function IsMobile(){
        const { width } = useViewport();
        const breakpoint = 620;
      
        return width < breakpoint;
    }

    const formatEventTime = (eventTime) => {
        if(!eventTime){
            return '';
        }
        var lclDate = new Date(eventTime);
        const format = 'HH:mm';
        return dateFnsFormat(lclDate, format);
    }

    const getFullDateTimeFormatted = () => {
        let date = getFullDateTime();
        if(!date){
            return null;
        }
        
        const format = 'MM/dd/yyyy HH:mm:ss';
        return dateFnsFormat(date, format);
    }

    const onSubmit = async () => {
        const formattedEventInfo = {
            action: eventInfo.action,
            ID: eventInfo.Id,
            title: title,
            eventTime: formatEventTime(eventTime),
            eventDate: getFullDateTime(),
            dateTimeStr: getFullDateTimeFormatted(),
            description: description };

        onDayEventSubmit(formattedEventInfo)
    }

    const renderUpdateBtn = () => {
        if(!IsMobile()){
            return <Button onClick={()=>onSubmit()} disabled = {!isSubmitValid()}>
                        <Text style={styles.text}>Submit</Text>
                    </Button>
        }

        return <button className="link-btn" onClick={()=>onSubmit()} disabled = {!isSubmitValid()}>
            <UpdateSharp/>                        
        </button>
    }

    const styles = StyleSheet.create({
        input: {
          height: 300,
          borderWidth: 1,
          padding: 10,
        },
        text: {
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'bold',
            letterSpacing: 0.2,
            color: 'white',
          },
    });

    return (
        <section>
            <Modal show={showModal} onHide={closeModal} onShow={onShowModal}>
            <Modal.Header closeButton style={{borderBottom:"none", borderTop:"none"}}>
            <Modal.Title>
            {dlgTitle}
            </Modal.Title>
            </Modal.Header>            
            <Modal.Body scrollable="true">
            <View style={{flex: 1, flexDirection: 'row'}}>
                <Text style={{textAlign: 'left'}}>Title:</Text>                    
            </View>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <input
                    placeholder={titlePlaceHolder} 
                    alt={titlePlaceHolder} 
                    title={titlePlaceHolder}
                    value={title}
                    onChange={handleTitleChange}/>
            </View>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <Text style={{textAlign: 'left'}}>Event time:</Text>                    
            </View>
            <View style={{flex: 1, flexDirection: 'row'}}>
                {renderTimeControl()}
            </View>
            <View style={{flex: 1, flexDirection: 'row', marginTop: 10}}>
                <Text style={{textAlign: 'left'}}>Description:</Text>
            </View>
            <View style={{flex: 1, flexDirection: 'row'}}>
                <SafeAreaView style={{flex: 1, flexDirection: 'row'}}>
                    <TextInput
                        multiline="true"
                        editable
                        style={styles.input}
                        onChangeText={onChangeDescription}
                        placeholder="Enter event description"
                        value={description}
                        />
                </SafeAreaView>
            </View>
            <View style={{ flex:1, justifyContent: "flex-end", flexDirection: "row", marginTop: 10}}>
                        {renderUpdateBtn()}
                    </View>
            </Modal.Body>
            </Modal>
        </section>
    );
}

export default DayEventDlg;