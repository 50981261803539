import React, { useState } from 'react';
import postAdService from '../../../shared/services/postAdService'
import '../../Home.css';
import { Text, View } from 'react-native';
import { convertFromRaw } from 'draft-js';
import PostAdEditor from '../../../shared/Controls/PostAdEditor'
import { EditorState } from 'draft-js';
import authenticationService from '../../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";
import CheckCircle from '@material-ui/icons/CheckCircle';
import Button from 'react-bootstrap/Button';

function HomepageAds() {
    const [activePosts, setActivePosts] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    React.useEffect(() => {
        async function fetchData() {
            let posts;
            try{
                posts = await postAdService.getActivePosts();
                setActivePosts(posts);
            }
            catch(e){
                console.log(e);
            }

            try {
                let unauthenticatedPosts = await postAdService.getUnauthenticatedPosts();
                if(unauthenticatedPosts !== undefined && unauthenticatedPosts != null){
                    if(posts !== undefined && posts != null){
                        var allPosts = [...posts, ...unauthenticatedPosts];
                        setActivePosts(allPosts);
                        return;
                    }
                }
            }
            catch(e){
                console.log(e);
            }
        }
        fetchData();

    }, []);

    const renderPostedAdDescription = (postedAd) => {
        if (!postedAd.isEditor && !postedAd.isHTML) {
            return <Text>{postedAd.description}</Text>
        }

        if (postedAd.isHTML && !postedAd.isEditor) {
            console.log("dangerouslySetInnerHTML");
            return (<div className="content" dangerouslySetInnerHTML={{ __html: postedAd.description }}></div>);
        }

        const contentState = convertFromRaw(JSON.parse(postedAd.description));
        const editorState = EditorState.createWithContent(contentState);

        return (
            <PostAdEditor
                onRichTextEditorStateChanged={null}
                initialEditorState={editorState}
                isReadOnly={true} />
        );
    }

    const handleApproveClick = async (postedAd) => {
        const response = await postAdService.approve(postedAd, !postedAd.isAdminApproved);
        if (!response.status) {
            postedAd.isError = true;
        }
        else {
            postedAd.isError = undefined;
        }

        forceUpdate();
    }

    const renderAdminApproveBtn = (postedAd) => {
        if (postedAd.isError === undefined) {
            let statusColor = postedAd.isAdminApproved ? "success" : "primary";

            return (
                <button className="link-btn" onClick={() => handleApproveClick(postedAd)}>
                    <CheckCircle color={statusColor} />
                </button>
            );
        }

        return (
            <button className="link-btn" onClick={() => handleApproveClick(postedAd)}>
                <CheckCircle color={"error"} />
            </button>
        );
    }
    const renderThumbnails = (postedAd) => {
        if(!postedAd.images || postedAd.images === undefined || postedAd.images.length === 0){
            return
        }

        var thumbnails = postedAd.images;
        return (<View style={{flexDirection: 'row'}}>
            {thumbnails.map(renderThumbnailImage)}
            </View>
        );
    }

    const renderThumbnailImage = (thmbnail) => {
        if(!thmbnail.image || thmbnail.image === undefined){
            return;
        }

        return(<View style={{marginRight: 10, justifyContent: "center", alignItems: "center"}}>
                <img src={`data:image/jpeg;base64,${thmbnail.image}`} width={100} height={100} alt=''/>                
               </View>
        );
    }

    const renderMoveForwardBtn = (postedAd) => {
        if(!postedAd || postedAd === null){
            return;
        }

        if(!postedAd.isAdminApproved){
            return;
        }

        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        let startDate = new Date(postedAd.startDate);
        
        if(startDate.getTime() === today.getTime()){
            return;
        }

        if(startDate.getTime() > today.getTime()){
            return;
        }

        if (postedAd.isError !== undefined){
            return;
        }

        return(
            <Button onClick={()=>handleRepostClick(postedAd)}>
                <Text>Re-Post</Text>
            </Button>
        );
    }

    const isAdCurrent = (postedAd) => {
        if(!postedAd || postedAd === null){
            return false;
        }

        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);

        let startDate = new Date(postedAd.startDate);
        let endDate = new Date(postedAd.endDate);

        if(endDate.getTime() < today.getTime()){
            console.log('JOPA');
            return false;
        }

        if(startDate.getTime() > today.getTime()){
            return false;
        }

        return true;
    }

    const handleRepostClick = async (postedAd) => {
        postAdService.repostAd(postedAd);
    }

    const renderAd = (postedAd, index) => {
        return (
            <div>
                <p />
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ flex: 0.15 }}>
                        <Text>{postedAd.firstName} {postedAd.lastName}</Text>
                    </View>
                    <View style={{ flex: 0.15 }}>
                        <Text>{postedAd.startDate} - {postedAd.endDate}</Text>
                    </View>
                    <View style={{ flex: 0.5, marginRight: 5 }}>
                        {renderPostedAdDescription(postedAd)}
                    </View>
                    <View style={{ flex: 0.1 }}>
                        {renderAdminApproveBtn(postedAd)}
                    </View>
                    <View style={{ flex: 0.1 }}>
                        {renderMoveForwardBtn(postedAd)}
                    </View>
                </View>
                {renderThumbnails(postedAd)}
            </div>
        );
    }

    const renderPostedAd = (postedAd, index) => {
        if (postedAd === undefined || postedAd === null) {
            return;
        }

        if(isAdCurrent(postedAd) === true){
            return (
                <div className='flex-container100' >
                    {renderAd(postedAd)}
                </div>
            );
        }

        return (
            <div className='flex-container100' style={{ backgroundColor: '#F7E6E8'}}>
                {renderAd(postedAd)}
            </div>
        );
    }

    const renderActieAds = () => {
        if (!activePosts || activePosts.length === 0) {
            return;
        }

        return (<div className='flex-container98'>
            {activePosts.map(renderPostedAd)}
        </div>);
    }

    const renderMainPage = (id) => {
        if (!authenticationService.isAdministrator()) {
            return <Navigate to="" />;
        }
    }

    return (
        <div>
            {renderMainPage()}
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                <h1>Main page ads</h1>
            </View>
            {renderActieAds()}
        </div>
    )
}

export default HomepageAds