import ShuleScheduleCalendar from './ShuleScheduleCalendar'

function ShulSchedule() {    
    return (
        <div>
            <ShuleScheduleCalendar/>            
        </div>
    );
}

export default ShulSchedule;