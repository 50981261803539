import React from 'react'
import { Text } from 'react-native';
import {Link } from "react-router-dom";

class HelpComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }        
    }

    handleSubmit = async(event) => {
        event.preventDefault();
    }

    render() {
        return (
            <section>
                <div><Text>We would certainly like to help you with any of your questions please click on the link below to contact us and somebody will get back to you</Text></div>
                <ul>
                    <li><Link to="/contactUs">Contact Us</Link></li>
                </ul>
            </section>
        );
    }
}
export default HelpComponent;