import { useState } from 'react';
import { View, Text } from 'react-native';
import postAdService from '../../shared/services/postAdService'

function ConfirmMainPagePost() {
    const [isConfirmationSuccess, setIsConfirmationSuccess] = useState(false);  
    const [isParseSuccess, setIsParseSuccess] = useState(true);
    const [isActivating, setIsActivating] = useState(false);
    const [isError, setIsError] = useState(false);

    window.onload = function (){
        onPageLoad();      
    }

    const onPageLoad = async () => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);

        if(!parsed || !parsed.token || !parsed.name || !parsed.email) {
            setIsParseSuccess(false);
            return;
        }

        setIsActivating(true);

        var result = await postAdService.confirmPost(parsed.token, parsed.name, parsed.email);
        setIsActivating(false);
        if(!result) {

            setIsError(true);
            return;
        }

        setIsConfirmationSuccess(true)
    }

    const renderInfo = () => {
        if(isConfirmationSuccess){
            return (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
                   <Text>Your email has been successfully confirmed</Text>
                   <Text>Your post will be displayed after the admin approval</Text>
                </View>
            );
        }

        if(!isParseSuccess){
            return (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
                   <Text>There appears to be an unauthorized usage of this site</Text>
                   <Text>Please use the site responsibly</Text>;
                </View>
            );
        }

        if(isActivating){
            return (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
                   <Text>We are trying to confirm you email</Text>
                   <Text>Please stand by</Text>
                </View>
            );
        }

        if(isError){
            return (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
                   <Text style={{color: 'red'}}>There was an error confirming your email</Text>
                   <Text>We apologize for the inconvenience</Text>
                   <Text>Please try again later</Text>
                </View>
            );
        }
    }

    return (
        <div>
            {renderInfo()}
        </div>
    );
}

export default ConfirmMainPagePost;