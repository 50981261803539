import React from 'react'
import './ImageSlider.css';

const ImageSlider = ({ slides }) => {
    const renderSlides = () => {
        if(!slides){
            return;
        }
        
        return(
            <div className='w-full h-full overflow-x-scroll whitespace-nowrap'>
                {slides.map((t) =>
                (
                    <img className='w-[100px] inline-block p-2 hover:scale-105 ease-in-out duration-300' 
                    src={`data:image/jpeg;base64,${t.image}`} alt='/'/>
                ))}
            </div>
        );
    }
    
    return(
        <div className='relative flex items-center'>
            {renderSlides()}
        </div>
    );
}

export default ImageSlider;