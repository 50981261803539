import helperUtil from 'util'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const projectsService = {
    getAllUsers,
    addContractorProject,
    getAllContractorProjects,
    deleteContractorProject
};

async function getAllUsers() {
    let users = {};

    await fetch(SERVER_URL + 'api/SourceContacts/getAll')
    .then(helperUtil.handleErrors)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        users = data;
        console.log(data);
    }).catch(function(error) {
        console.log(error);
    });

    return users;
}

async function addContractorProject(title, description) {
    const data = JSON.stringify({ title: title, description: description});
    let result = false;

    await fetch(SERVER_URL + 'api/ContractorProjects/AddContractorProject', {  method: 'POST',  body: data, headers: {
        'Content-Type': 'application/json',
      }}).then(helperUtil.handleErrors)
      .then(function(response) {      
          console.log(response);
          return response.json();
        }).then(function(data) {
          console.log(data);
          result = data;
      }).catch(function(error) {
          console.log('There was an error during submission' + error);
      });

      return result;
}

async function deleteContractorProject(id) {
    let result = false;

    await fetch(SERVER_URL + 'api/ContractorProjects/deleteContractorProject/' + id)
    .then(helperUtil.handleErrors)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        result = data;
        //console.log(data);
    }).catch(function(error) {
        console.log(error);
    });

    return result;
}

async function getAllContractorProjects() {
    let projects = {};

    await fetch(SERVER_URL + 'api/ContractorProjects/getAllContractorProjects/')
    .then(helperUtil.handleErrors)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        projects = data;
        console.log(data);
    }).catch(function(error) {
        console.log(error);
    });

    return projects;
}

export default projectsService;