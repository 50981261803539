function ConfigVariables() {
    return (
        <div>
            <h1>
                Config Variables
            </h1>
        </div>
      );
}

export default ConfigVariables