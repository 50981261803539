import React from 'react'

class ContactUsSuccess extends React.Component {
  render () {
    return (
        <div>
        <h3 style={{ color: 'blue' }}>Your inquiry has been submitted successfully</h3>
        <h4 style={{ color: 'blue' }}>Someone will contact you</h4>
        <h6 style={{ color: 'blue' }}>Thank you!</h6>
        </div>
    )
  }
}
export default ContactUsSuccess;