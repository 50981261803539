import { View, Text, TextInput, StyleSheet } from 'react-native';
import React, { useState, useRef, useMemo } from 'react';
import PostAdEditor from '../../shared/Controls/PostAdEditor'
import './ClassifiedAdPost.css';
import IncrementDecrementCounter from '../../shared/Controls/IncrementDecrementCounter'
import Button from 'react-bootstrap/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import uuid from 'react-native-uuid';
import classifiedAdService from '../../shared/services/classifiedAdService'
import ShowImageDlg from './ShowImageDlg'
import Cancel from '@material-ui/icons/Cancel';
import { EditorState, ContentState  } from 'draft-js';
import { Navigate } from "react-router-dom";
import { convertToRaw, convertFromRaw } from 'draft-js';
import authenticationService from '../../shared/services/authentication.service'
import { confirmAlert } from 'react-confirm-alert';
import utilService from '../../shared/services/utilservice'

function ClassifiedAdPostDesktop({category,editPageId,editMode}) {
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [listInputPics, setListInputPics] = React.useState([]);
    const inputFile = useRef(null);
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [isAdAdded, setIsAdAdded] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isUploadingImageError, setIsUploadingImageError] = useState(false);
    const [isShowImgDlg, setIsShowImgDlg] = useState(false);
    const [imageIdToShow, setImageIdToShow] = useState('');
    const [isDeletingImage, setIsDeletingImageageIdToShow] = useState(false);
    const timerFileExtensionRef = useRef(null);
    const [showFileExtensionWarning, setShowFileExtensionWarning] = React.useState(false);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [errorText, setErrorText] = useState('');
    const [initialEditorState, setInitialEditorState] = useState(false);
    const [isTelephoneValid, setIsTelephoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [ispostedSuccess, setPostedSuccess] = useState(false); 
    const [duration, setDuration] = useState(1);
    const timerErrorTextRef = useRef(null);
    const [isModified, setIsModified] = useState(false);
    const [initialDuration, setInitialDuration] = useState(8);
    const [isEditting, setIsEditting] = useState(false);
    const [isRemoveSuccess, setIsRemoveSuccess] = useState(false);
    const [miscellaneousText, setMiscellaneousText] = useState('');
    
    // memorize the value
    // this is to avoid "React Hook React.useEffect has missing dependencies" warning
    const pageId = useMemo(() => {
        if(editPageId === null || editPageId.length === undefined || !editPageId || editPageId.length === 0){
            return uuid.v1();
        }

        return editPageId;
    }, [editPageId]);
    
    React.useEffect(() => {
        const onUnload = (event ) => {
            if(!isAdAdded && isModified){
                //classifiedAdService.cleanupIntermediaryAd(pageId);
                event.preventDefault();
                event.returnValue  = 'Your changes will be lost';
            }
        };

        async function fetchData() {
            console.log('fetching data for edit');
            let ad = await classifiedAdService.getForEdit(editPageId);
            console.log('get for edit')
            if(ad){
                console.log(ad)
                setTitle(ad.title);
                setShortDescription(ad.shortDescription);

                if(ad.description && ad.description !== undefined && ad.description.length > 3){
                    let editorState;
                    if(!utilService.isJsonString(ad.description)){
                        editorState = EditorState.createWithContent(ContentState.createFromText(ad.description))
                    }
                    else{
                        const contentState = convertFromRaw(JSON.parse(ad.description));
                        editorState = EditorState.createWithContent(contentState);
                    }

                    setInitialEditorState(editorState);
                    setEditorState(editorState);
                }

                setInitialDuration(ad.durationWeeks);
                setListInputPics(ad.thumbnails);
                setTelephone(ad.telephone);
                setEmail(ad.email);
                setIsEmailValid(utilService.isEmailPatternValid(ad.email));
                setIsEditting(true)
            }
        }
        
        if(editMode){
            fetchData();
        }
        
        console.log("mounted " + pageId)
        clearTimeout(timerFileExtensionRef.current);
        clearTimeout(timerErrorTextRef.current);
        window.addEventListener("beforeunload", onUnload);
        return () => window.removeEventListener("beforeunload", onUnload);
    },[pageId,isAdAdded,isModified,editMode,editPageId]);

    const hideExtensionWarning = () => setShowFileExtensionWarning(false)
    const hideErrorText = () => setErrorText('')

    const handleTitleChange = async(value) => {
        setTitle(value);
        console.log("title " + title);
        setIsModified(true);
    }

    const handleShortDescriptionChange = async(value) => {
        setShortDescription(value);
        isTextValid(value, 50)
        setIsModified(true);
    }

    const handleTelephoneChange = async(value) => {
        setTelephone(value);
        setIsTelephoneValid(true)
        setIsModified(true);

        if(!value || value.length === 0){
            return;
        }

        var pattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
        if (!value.match(pattern)) {      
            setIsTelephoneValid(false)
            return;
        }
    }

    const handleEmailChange = async(value) => {
        setEmail(value);
        setIsModified(true);

        console.log(value);
        if(!value || value === 0){
            return;
        }
        
        setIsEmailValid(utilService.isEmailPatternValid(value));
    }

    const InitWindowStyles = StyleSheet.create({
        root: {
          flex: 1,
          flexDirection: "column",
        },
        rowContainer: {
          flex: 1, 
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        },
        text: {
          flex: 0.3,
          textAlign: "right", 
          marginRight: 10, 
          marginLeft: 10
        },
        textLeft: {
            flex: 0.1,
            textAlign: "left", 
            marginRight: 10, 
            marginLeft: 10
          },
        textInput: {
          flex: 1,
          backgroundColor: 'white', 
          borderColor: 'black',
          marginRight: 10
        },
        updateBtnView: {
            flex: 0.95, 
            justifyContent: "flex-end", 
            alignItems: 'center', 
            flexDirection: "row", 
            marginRight: 10, 
            marginTop: 10
        },
        textBtn: {
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'bold',
            letterSpacing: 0.2,
            color: 'white',
        },
        attachFileBtnView: {
            flex: 1, 
            justifyContent: "flex-start", 
            alignItems: 'center', 
            flexDirection: "row", 
            marginRight: 10, 
            marginTop: 10,
            marginLeft: 10
        }
      })

    const onShowPicture = (imageId) => {
        setIsShowImgDlg(true);
        setImageIdToShow(imageId);
    };

    const onCancelPicture = async (imageId) => {
        setIsDeletingImageageIdToShow(true)
        var result = await classifiedAdService.deleteImage(pageId, imageId);
        if(result){
            var picRemoved = listInputPics.filter((element) => { return element.id !== imageId; })
            setListInputPics(picRemoved);
        }
        setIsDeletingImageageIdToShow(false)
    };

    const onUploadFileClick = () => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    const onPostClick = async () => {
        let data = '';
        const state = editorState.getCurrentContent();
        const editorText = editorState.getCurrentContent().getPlainText('\u0001');
        if (editorText.length < 2) {
            data = '';
        }
        else {
            data = JSON.stringify(convertToRaw(state));
        }

        let userId = undefined;
        if(authenticationService.isUserLoggedIn()){
            userId = authenticationService.getUserID();
        }

        var result = await classifiedAdService.postAd(title, 
                                                      shortDescription,
                                                      data,
                                                      pageId,
                                                      telephone,
                                                      email,
                                                      userId,
                                                      duration,
                                                      category);
        
        if(result){
            setIsAdAdded(true);
            setPostedSuccess(true);
            return;
        }

        setErrorText('There was an error posting the ad. Please try again later');
        clearTimeout(timerErrorTextRef.current);
        timerErrorTextRef.current = setTimeout(hideErrorText, 5000);
        
        setPostedSuccess(false);
    };

    const deleteAd = async () => {
        setMiscellaneousText('Trying to remove the ad\r\nPlease wait')
        var result = await classifiedAdService.deleteAd(pageId);
        if(!result){
            setErrorText('There was an error removing your ad');
            clearTimeout(timerErrorTextRef.current);
            timerErrorTextRef.current = setTimeout(hideErrorText, 5000);
            setIsRemoveSuccess(false);
            return;
        }

        setIsRemoveSuccess(true);
    }

    const onRemoveClick = async () => {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure to delete your ad?',
            overlayClassName: "overlay-custom-class-name",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { deleteAd() }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('no deletion called`') }
                }
            ]
        });
    }

    const onAddFile = async (event) => {
        if(!event || !event.target || !event.target.files || event.target.files.length === 0){
            return;
        }

        var extension = event.target.files[0].name.split('.').pop()
        console.log(extension);
        switch(extension) {
            case 'JPG':
            case 'jpg':
            case 'GIF':
            case 'gif':
            case 'BMP':
            case 'bmp':
            case 'PNG':
            case 'png':
              break;
            default:
                setShowFileExtensionWarning(true);
                clearTimeout(timerFileExtensionRef.current);
                timerFileExtensionRef.current = setTimeout(hideExtensionWarning, 3000);                
                return;
        }
        
        var imageId = uuid.v1();
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        console.log(file);
        
        setIsUploadingImage(true);
        setIsUploadingImageError(false);

        let response = await classifiedAdService.addNewImage(file, pageId, imageId);
        setIsUploadingImage(false);

        if(!response){
            setIsUploadingImageError(true);
            return;
        }
        
        setIsUploadingImageError(false);
        setListInputPics(response);
        setIsModified(true);
    }

    const renderThumbnailImage = (thmbnail) => {
        return(
            <View style={{marginRight: 10, justifyContent: "center", alignItems: "center"}}>
                <button className="link-btn" onClick={() => onShowPicture(thmbnail.id)}>
                <img src={`data:image/jpeg;base64,${thmbnail.image}`} width={100} height={100} alt=''/>
                </button>
                <View style={{marginTop: 5, justifyContent: "center", alignItems: "center"}}>
                <button className="link-btn" onClick={() => onCancelPicture(thmbnail.id)}>
                    <Cancel style={{ color: '#B77263' }}/>
                </button>
                </View>                
            </View>
        );
    }

    const renderInputPictures = () => {
        if(!listInputPics || listInputPics.length === 0){
            return;
        }

        return (<View style={{flexDirection: 'row'}}>
                    {listInputPics.map(renderThumbnailImage)}
                </View>);        
    }

    const renderUploadingImage = () => {
        if(!isUploadingImage){
            return;
        }

        return (
            <Text style={{flex: 0.3, marginBottom: 10, marginTop: 10, color: 'blue'}}>Uploading image</Text>                        
        );
    }

    const renderDeletingImage = () => {
        if(!isDeletingImage){
            return;
        }

        return (
            <Text style={{flex: 0.3, marginBottom: 10, marginTop: 10, color: '#EE7358'}}>Deleting image</Text>                        
        );
    }

    const renderFileExtensionWarning = () => {
        if(!showFileExtensionWarning){
            return;
        }

        return (
            <Text style={{flex: 0.3, marginBottom: 10, marginTop: 10, color: 'red'}}>Unsupported file format</Text>                        
        );
    }

    const renderUploadingImageError = () => {
        if(!isUploadingImageError){
            return;
        }

        return (
            <Text style={{flex: 0.3, marginBottom: 10, marginTop: 10, color: 'red'}}>Error uploading image</Text>                        
        );
    }

    const renderShowImgDlg = () => {
        return <ShowImageDlg showModal={isShowImgDlg}
            onClose={onShowImgDlgClose}
            imageIdToShow={imageIdToShow}
            source={"classifieds"}/>
    }

    function onShowImgDlgClose() {
        setIsShowImgDlg(false);
    }

    const renderTitleLabel = () => {
        if(!title || title.length === 0){
            return (                
                <Text style={InitWindowStyles.text}>Title:
                    <Text style={{color: 'red'}}> *</Text>    
                </Text>
            )
        }
        return <Text style={InitWindowStyles.text}>Title:</Text>
    }

    const renderTelephoneLabel = () => {
        if(!isTelephoneValid){
            return (                
                <Text style={{flex: 0.05, flexDirection: "row",justifyContent: "flex-end",alignItems: "center"}}>
                    Telephone:
                    <Text style={{color: 'red'}}> *</Text>    
                </Text>                
            )
        }
        return (
            <Text style={{flex: 0.05, flexDirection: "row",justifyContent: "flex-end",alignItems: "center"}}>
                    Telephone:
                </Text>                

        );
    }

    const renderEmailLabel = () => {
        if(!isEmailValid){
            return (
                <Text style={{flex: 0.05, flexDirection: "row",justifyContent: "flex-end",alignItems: "center"}}>Email:
                    <Text style={{color: 'red'}}> *</Text>
                </Text>
            );
        }

        return (
            <Text style={{flex: 0.05, flexDirection: "row",justifyContent: "flex-end",alignItems: "center"}}>Email:</Text>
        );
    }

    const onRichTextEditorStateChanged = (state) => {
        setEditorState(state);
        const editorText = state.getCurrentContent().getPlainText('\u0001');
        console.log(editorText)
        isTextValid(editorText, 2000);
        setIsModified(true);
    }

    const isTextValid = (inputText, numberOfWords) => {
        let words = inputText.split(' ').filter(Boolean);
        
        if(inputText && inputText.length > 0 && words.length > numberOfWords){
            setErrorText('Exceeded number of words');
            return false;
        }

        setErrorText('');
        return true;
    }

    const isFormValid = () => {
        if(!title || title.length === 0){
            return false;
        }

        if(errorText && errorText.length > 0){
            return false;
        }

        return isTelephoneValid && isEmailValid;
    }

    const renderPostedSuccess = () => {
        if (ispostedSuccess) {
          return <Navigate
          to={{
            pathname: "/classifiedPosted",
            search: "?email=" + email + "&title=" + title,
            state: { referrer: "currentLocation" }
          }}
        />
        }
    }

    const renderRemoveSuccess = () => {
        if (isRemoveSuccess) {
          return <Navigate
          to={{
            pathname: "/classifiedRemoveSuccess",
            search: "?email=" + email + "&title=" + title,
            state: { referrer: "currentLocation" }
          }}
        />
        }
    }

    const renderMiscellaneous = () => {
        if(!miscellaneousText || miscellaneousText.length < 1){
            return;
        }

        return (
            <View style={{flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                <View style={{flex: .5, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                <Text style={{textAlign: 'center', color: 'blue', fontWeight: 'bold'}}>{miscellaneousText}</Text>
                </View>
            </View>
        );
    }

    const renderValidation = () => {
        if(!errorText || errorText.length < 1){
            return;
        }
        
        return (
            <View style={{flex: 1, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                <View style={{flex: .5, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                <Text style={{textAlign: 'center', color: 'red'}}>{errorText}</Text>
                </View>
            </View>
        );
    }

    const renderPostButton = () => {
        if(!isEditting){
            return (<Text style={InitWindowStyles.textBtn}>Post</Text>);
        }

        return (<Text style={InitWindowStyles.textBtn}>Update</Text>);
    }

    const renderRemoveButton = () => {
        if(!isEditting){
            return;
        }

        return (<View style={{marginLeft: 10}}>
                    <Button variant="dark" onClick={onRemoveClick}>                            
                        <Text style={InitWindowStyles.textBtn}>Remove</Text>                            
                    </Button>
                </View>);
    }

    const onDurationChannged = async (count) => {
        setDuration(count);
        setIsModified(true);
    }
    
    const renderItems = () => {
        return(
            <div>
                <View style={InitWindowStyles.updateBtnView}>
                    <div style={{ marginRight: '.5rem'}}>
                            <IncrementDecrementCounter initialCount={initialDuration} isWeeks={true} maxUnits={16} onCounterChanged={onDurationChannged}/>
                        </div>
                        <Button disabled={!isFormValid()} onClick={onPostClick}>
                            {renderPostButton()}
                        </Button>
                        {renderRemoveButton()}
                    </View>
                <View style={InitWindowStyles.root}>
                    <View style={InitWindowStyles.rowContainer}>
                        {renderTitleLabel()}
                        <TextInput
                            placeholder="Enter Title (required)"
                            autoCorrect={false}
                            style={InitWindowStyles.textInput}
                            value={title}
                            onChangeText={handleTitleChange}
                        />
                    </View>
                    <View style={InitWindowStyles.rowContainer}>
                        <Text style={InitWindowStyles.text}>Short Description:</Text>
                        <TextInput
                            autoCorrect={false}
                            placeholder="Enter Short Description (optional)"
                            style={InitWindowStyles.textInput}
                            value={shortDescription}
                            onChangeText={handleShortDescriptionChange}
                        />
                    </View>
                </View>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: "flex-end", marginRight: 10, zIndex: 1000}}>
                        <View style={{flex: 0.98 }}>
                        <Text style={{flex: 0.3, marginBottom: 10}}>Description</Text>
                                <PostAdEditor
                                onRichTextEditorStateChanged={onRichTextEditorStateChanged}
                                initialEditorState={initialEditorState}
                                />
                        </View>
                </View>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: "flex-end", marginRight: 10, alignItems: "center"}}>
                        <View style={{flex: 0.1, flexDirection: 'row', marginTop: 10, alignItems: "center" }}>
                        <Text style={{ marginRight: 10}}>Attach Pictures:</Text>
                        <button className="link-btn" onClick={onUploadFileClick}>
                            <AttachFile style={{justifyContent: "flex-start", marginRight: 10}}/>
                        </button>
                        </View>
                        
                        <View style={{flex: 0.88, flexDirection: 'row', marginTop: 10, marginLeft: 20 }}>
                        {renderInputPictures()}
                        </View>

                        <input type='file' id='file' 
                        ref={inputFile} 
                        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                        onChange={onAddFile} 
                        disabled={isUploadingImage}
                        style={{display: 'none'}}/>                        
                </View>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: "flex-end", marginRight: 10, zIndex: 1000}}>
                        <View style={{flex: 0.98 }}>
                        {renderUploadingImage()}
                        {renderUploadingImageError()}
                        {renderDeletingImage()}
                        {renderFileExtensionWarning()}
                        </View>
                </View>
                <View style={{flex: 1,flexDirection: "column", zIndex: 0}}>
                    <View style={{flex: 1, flexDirection: "row",justifyContent: "flex-end",alignItems: "center", marginTop: 20, zIndex: 0}}>
                        {renderTelephoneLabel()}
                        <TextInput
                            placeholder="Enter Contact Telephone (optional)"
                            autoCorrect={false}
                            value={telephone}
                            style={{flex: 0.93,
                                backgroundColor: 'white', 
                                borderColor: 'black',
                                marginRight: 10,
                                zIndex: 0}}
                            onChangeText={handleTelephoneChange}
                        />
                    </View>
                </View>
                <View style={{flex: 1,flexDirection: "column"}}>
                    <View style={{flex: 1, flexDirection: "row",justifyContent: "flex-end",alignItems: "center", marginTop: 20}}>
                        {renderEmailLabel()}
                        <TextInput
                            placeholder="Enter Contact Email (required)"
                            autoCorrect={false}
                            value={email}
                            style={{flex: 0.93,
                                backgroundColor: 'white', 
                                borderColor: 'black',
                                marginRight: 10}}
                            onChangeText={handleEmailChange}
                        />
                    </View>
                </View>
            </div>
        );        
    }

    // main render
    return (
        <div>
            {renderMiscellaneous()}
            {renderValidation()}
            {renderItems()}
            {renderShowImgDlg()}
            {renderPostedSuccess()}
            {renderRemoveSuccess()}
        </div>
    );
}

export default ClassifiedAdPostDesktop;