import React, { useState } from 'react';
import authenticationService from '../../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";
import { RadioButton, Text } from 'react-native-paper';
import '../../Home.css';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import Grid from '@material-ui/core/Grid';
import dayScheduler from './DayScheduler';
import weekScheduler from './WeekScheduler';
import { View } from 'react-native';
import helperUtil from '../../../shared/Util'

function Scheduler() {
    const [scheduledDay, setScheduledDay] = useState(new Date());
    const [scheduleDay, setScheduleDay] = useState(true);
    const [scheduleWeek, setScheduleWeek] = useState(false);
    const [daySchedulerKey, setDaySchedulerKey] = useState(1);
    
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

    React.useEffect(() => {
        async function fetchData() {
            
        }
        fetchData();
        
    }, []);

    const renderMainPage = (id) => {
        if(!authenticationService.isAdministrator()){
            return <Navigate to="/" />;
        }
    }

    function formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }

    function parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
          return parsed;
        }
        return undefined;
    }

    const getDayName = (date) => {
        if(!date){
            return '';
        }
        return helperUtil.getDayOfTheWeek(date);
    }

    const getMonthName = (date) => {
        if(!date){
            return '';
        }
        
        return months[ date.getMonth() ];
    }

    const formatTitleDate = (date) => {
        if(!date){
            return '';
        }
        var lclDate = new Date(date);
        const format = 'dd yyyy';
        return dateFnsFormat(lclDate, format);
    }

    const renderScheduledDate = () => {
        const FORMAT = 'MM/dd/yyyy';
        return <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format={FORMAT}
        placeholder={dateFnsFormat(scheduledDay, FORMAT)}
        dayPickerProps={{
            month: scheduledDay,
            showWeekNumbers: true,
            todayButton: 'Today',
        }} 
        onDayChange={
            day => {
                if(moment.isDate(day)){
                    setScheduledDay(day);
                    setDaySchedulerKey(daySchedulerKey + 1);
                }
                console.log('scheduledDay ' + scheduledDay);                
            }
        } />;    
    }

    const renderTable = () => {
        return (<div>
                    <View style={{flex: 0.95, flexDirection: 'row', justifyContent: "center"}}>
                        <h4>{getDayName(scheduledDay)} {getMonthName(scheduledDay)} {formatTitleDate(scheduledDay)}</h4>
                    </View>
                    {renderScheduledDate()}
                    <View style={{flex: 0.95, flexDirection: 'row', justifyContent: "center", alignItems: 'center'}}>
                        <Text>Day</Text>
                        <RadioButton status={scheduleDay ? 'checked' : 'unchecked'} onPress={onDayRangeChanged}/>                        
                        <Text>Week</Text>
                        <RadioButton status={scheduleWeek ? 'checked' : 'unchecked'} onPress={onWeekRangeChanged}/>                        
                    </View>

                    <View style={{flex: 1, flexDirection: 'row'}}>
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} align="center">
                            {renderDayScheduler()}
                            </Grid>                        
                        </Grid>
                    </View>
                </div>
        );
    }

    const renderDayScheduler = () => {
        if(scheduleWeek){
            return <weekScheduler.WeekScheduler date={scheduledDay}/>;
        }

        return <dayScheduler.DayScheduler key={daySchedulerKey} isDaySchedule={scheduleDay} date={scheduledDay}/>;
    }

    const onDayRangeChanged = event => {
        setScheduleWeek(false);
        setScheduleDay(true);
        console.log('Day schedule is on')
    };

    const onWeekRangeChanged = event => {
        console.log('Week schedule is on')
        setScheduleWeek(true);
        setScheduleDay(false);
    };

    return (
        <div className="center">
            {renderMainPage()}
            {renderTable()}
        </div>
      )
}

export default  Scheduler