import React, { useState, useCallback } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import classifiedAdService from '../../shared/services/classifiedAdService'
import { convertFromRaw } from 'draft-js';
import { EditorState, ContentState } from 'draft-js';
import PostAdEditor from '../../shared/Controls/PostAdEditor'
import ImageSlider from '../../shared/Controls/ImageSlider'
import { Navigate } from "react-router-dom";
import utilService from '../../shared/services/utilservice'

function ClassifiedsCategoryViewer({category}) {
    const [verboseCategory, setVerboseCategory] = useState('');
    const [ads, setAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewAdID, setViewAdID] = useState('');
    const [isAdSelected, setIsAdSelected] = useState('');    
    
    const getAndSetVerboseCategory = useCallback(async () => {
        switch (category) {
            case 'RE':
                setVerboseCategory("Real Estate");
                break;
            case 'FS':
                setVerboseCategory("For Sale");
                break;
            case 'SE':
                setVerboseCategory("Services");
                break;
            default:
                setVerboseCategory("Other");
                break;            
        }
    }, [category])

    React.useEffect(() => {
        async function fetchData() {
            console.log('ClassifiedsCategoryViewer ' + category);
            let ads = await classifiedAdService.getAllForCategory(category);
            console.log(ads)
            setAds(ads);
            setIsLoading(false);
        }
        getAndSetVerboseCategory();
        fetchData();

    }, [category, getAndSetVerboseCategory]);

    const renderCategoryTitle =  () => {
        return(<View style={{ flex: .98, 
                    justifyContent: 'flex-start', 
                    marginLeft: 10, 
                    marginRight: 20, 
                    marginTop: 10 }}>
                    <Text style={{fontWeight: 'bold', fontSize: 14, backgroundColor: "#CED2EF", padding: 5}}> {verboseCategory}</Text>
                </View>
        );
    }

    const renderThumbnails = (ad) => {
        return (
            <ImageSlider
                slides={ad.thumbnails}
            />
        );
    }

    const renderIsLoading =  () => {
        if(!isLoading){
            return;
        }

        return(<View style={{ flex: .98, 
            justifyContent: 'flex-start',                            
            marginTop: 10}}>
                <Text style={{fontWeight: 'bold', fontSize: 16}}>Loading. Please wait</Text>
            </View>
        );
    }

    const handleSelectedFirstAd = async (ad) => {
        console.log(ad)
        setViewAdID(ad.id)
        setIsAdSelected(true)
    }

    const onAddPostClick = async (imageId) => {
        window.location.href = "/classifiedPostHome"
    }

    const renderNoAds = () => {
        if(isLoading || (ads && ads !== undefined && ads.length > 0)){
            return;
        }

        return (
            <View style={{ flex: .98, flexDirection: 'row',
                justifyContent: 'center', alignItems: 'center', marginLeft: 20}}>
                <Text style={{fontWeight: 'bold', fontSize: 20}}>Currently there are no ads in '{verboseCategory}' category. </Text>
                <TouchableHighlight onPress={onAddPostClick}>
                    <Text style={{fontWeight: 'bold', fontSize: 20, color:'blue'}}>Be the first one to add a post</Text>
                </TouchableHighlight>
            </View>
        );
    }

    const renderAdContent = () => {
        if(!isAdSelected){
            return;
        }

        return <Navigate
          to={{
            pathname: "/classifiedsAdViewer",
            search: "?ad=" + viewAdID
          }}
        />        
    }

    const renderContent =  (ad) => {
        return(<TouchableHighlight onPress={() => handleSelectedFirstAd(ad)}>
                    <View style={{ flex: .98, 
                        justifyContent: 'flex-start',                            
                        marginTop: 10}}>
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>{ad.title}</Text>
                        <Text style={{fontSize: 14}}>{ad.shortDescription}</Text>        
                        {renderAdDescription(ad)}
                        {renderThumbnails(ad)}
                        <Text style={{fontWeight: 'bold', fontSize: 14, backgroundColor: "#A9DE72", padding: 2}}></Text>
                    </View>
                </TouchableHighlight>
        );
    }
    const renderAdDescription = (ad) => {
        if(!ad || !ad.description || ad.description === undefined || ad.description.length < 3){
            return;
        }

        let editorState;
        if(!utilService.isJsonString(ad.description)){
            editorState = EditorState.createWithContent(ContentState.createFromText(ad.description))
        }
        else{
            const contentState = convertFromRaw(JSON.parse(ad.description));
            editorState = EditorState.createWithContent(contentState);
        }

        return(<PostAdEditor
                onRichTextEditorStateChanged={null}
                initialEditorState={editorState}
                isReadOnly={true} />
        
            );
    }

    const renderCompressedContent =  () => {
        if(!ads || ads === undefined || ads === null || ads.length === 0){
            return;
        }

        return ( <View style={{ flex: .98, 
                    justifyContent: 'flex-start', 
                    marginLeft: 10, 
                    marginRight: 20, 
                    marginTop: 10 }}>
                        {ads.map(renderContent)}                        
                    </View>                 
        );
    }

    return(<div>
        {renderIsLoading()}
        {renderCategoryTitle()}
        {renderCompressedContent()}
        {renderAdContent()}
        {renderNoAds()}
    </div>

    );
}

export default ClassifiedsCategoryViewer;