import { useState } from 'react';
import { Text } from 'react-native';
import helperUtil from '../shared/Util'
import LoginComponent from './Login';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Activate() {
    const [isConfirmationSuccess, setIsConfirmationSuccess] = useState(false);    
    const [isParseSuccess, setIsParseSuccess] = useState(false);
    const [isTokenExpired, setTokenExpired] = useState(false);
    const [status, setStatus] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    
    const handleLogin = event => {
      setShowLogin(true);
      window.helloComponent.openModal();    
    }

    window.onload = function (){
      onPageLoad();      
    }

    const renderInfo = () => {
      if(isConfirmationSuccess){
        return <Text>Your email has been successfully confirmed</Text>;
      }
      
      if (isParseSuccess && !isTokenExpired && status) {
          return <Text>We are trying to activate your account. Please stand by</Text>;
        }

        if(isTokenExpired){
          return <span style={{color: "red"}}>Your token has expired</span>
        }

        if(!isParseSuccess || !isConfirmationSuccess) {
          return <span style={{color: "red"}}>There was an error during the activation. Please try again after some time or try to re-register</span>
        }
      }

      const onPageLoad = () => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);

        if(!parsed || !parsed.email || !parsed.token) {
            setIsParseSuccess(false);
            return;
        }

        setIsParseSuccess(true);

        const data = JSON.stringify({ email: parsed.email, token: parsed.token, password: ''});

        fetch(SERVER_URL + 'api/RegisterUser/ActivateEmail/', {  method: 'POST',  body: data, headers: {
            'Content-Type': 'application/json',
          }}).then(helperUtil.handleErrors)
          .then(function(response) {      
              console.log(response);              
              return response.json();
            }).then(function(data) {
              console.log(data);
              if(data.isTokenExpired){
                setTokenExpired(true);
                setStatus(false);
                return;
              }
              
              if(!data.isEmailConfirmed) {
                setTokenExpired(false);
                setIsConfirmationSuccess(false);
                setStatus(false);
                return;
              }

              if(!data.status)
              {
                setIsConfirmationSuccess(false);
                setStatus(false);
                setTokenExpired(false);
                console.log(data.status);
                return;
              }

              console.log(data.status);
              setTokenExpired(false);
              setIsConfirmationSuccess(true);
              setStatus(true);
              handleLogin();
      
          }).catch(function(error) {
              console.log(error);
              setStatus(false);
          });
      }

      return (
        <div className="wrapper">
        <br></br>
        {renderInfo()}
        <LoginComponent showLogin={showLogin}/>
        </div>
    )
}

export default Activate