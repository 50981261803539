import classifiedAdService from '../../shared/services/classifiedAdService'
import React, { useState } from 'react';
import { View, Text } from 'react-native';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import PostAdEditor from '../../shared/Controls/PostAdEditor'
import { EditorState, ContentState } from 'draft-js';
import { convertFromRaw } from 'draft-js';
import utilService from '../../shared/services/utilservice'

function ClassifiedsAdViewer() {
    const [ad, setAd] = useState({});
    const [images, setImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowNextBtn, setShowNextBtn] = useState(false);
    const [isShowPrevBtn, setShowPrevBtn] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const queryString = require('query-string');
            const parsed = queryString.parse(window.location.search);
            console.log('parsed');
            console.log(parsed.ad);

            let result = await classifiedAdService.getAd(parsed.ad);
            const lclImages = [];
            if(result && result !== undefined && result !== null && result.firstImage && result.firstImage !== null){
                lclImages[0] = result.firstImage;
                for(let i = 1; i < result.numberOfImages; i++){
                    lclImages.push(null);
                }

                setImages(lclImages);
                setImageIndex(0);
            }

            setAd(result)
            setIsLoading(false)

            var allImgsExceptFirst = await classifiedAdService.getAllImagesExceptFirst(parsed.ad);
            if(!allImgsExceptFirst || allImgsExceptFirst === undefined || allImgsExceptFirst === null){
                return;
            }
            
            for(let j = 0; j < allImgsExceptFirst.length; j++){
                lclImages[j+1] = allImgsExceptFirst[j];                
            }

            if(lclImages.length > 1){
                setShowNextBtn(true);
            }
            setImages(lclImages);            
        }
        fetchData();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    const isMobile = width <= 768;

    const handleNextPic = async () => {
        if(imageIndex + 1 >= images.length){
            setShowNextBtn(false);
            setShowPrevBtn(true);
            return;
        }

        var test = images[imageIndex + 1];
        if(test && test !== undefined && test !== null && test.image != null){
            setImageIndex(imageIndex + 1)
            setShowPrevBtn(true);
            if(imageIndex + 2 >= images.length){
                setShowNextBtn(false);
            }
            return;
        }
        
        setShowPrevBtn(true);
        setShowNextBtn(false);
    }

    const handlePrevPic = async () => {
        if(imageIndex <= 0){
            setShowPrevBtn(false);
            setShowNextBtn(true);
            return;
        }

        var test = images[imageIndex - 1];
        if(test && test !== undefined && test !== null && test.image != null){
            setImageIndex(imageIndex - 1)
            setShowNextBtn(true);
            if(imageIndex - 1 === 0){
                setShowPrevBtn(false);
            }
            return;
        }

        setShowPrevBtn(false);
    }

    const renderNextBtn = () => {
        if(!isShowNextBtn){
            return;
        }

        return(<button className="link-btn"  onClick={()=>handleNextPic()}>
                <ArrowForwardIos/>                        
               </button>
        );
    }

    const renderPrevBtn = () => {
        if(!isShowPrevBtn){
            return;
        }

        return(<button className="link-btn" onClick={()=>handlePrevPic()}>
                <ArrowBackIos/>                        
               </button>
        );
    }

    const renderImage = () => {
        if(imageIndex === -1){
            return;
        }

        if(!isMobile){
            return( <View style={{ flex: 1, 
                                justifyContent: 'center', 
                                flexDirection: "row", 
                                alignItems: 'center'}}>
                                    <View style={{justifyContent: "flex-end"}}>
                                    {renderPrevBtn()}
                                    </View>
                                    <img 
                                        style={{width: 700, height: 700, objectFit: 'contain'}}
                                        src={`data:image/jpeg;base64,${images[imageIndex].image}`}
                                        alt=''/>
                                    <View style={{justifyContent: "flex-start"}}>
                                        {renderNextBtn()}    
                                    </View>
                        </View>
            );
        }

        return( <View style={{ flex: 1, 
            justifyContent: 'center', 
            flexDirection: "row", 
            alignItems: 'center'}}>
                <View style={{justifyContent: "flex-end"}}>
                {renderPrevBtn()}
                </View>
                <img 
                    style={{width: 300, height: 300, objectFit: 'contain'}}
                    src={`data:image/jpeg;base64,${images[imageIndex].image}`}
                    alt=''/>
                <View style={{justifyContent: "flex-start"}}>
                    {renderNextBtn()}    
                </View>
    </View>
);
    }

    const renderTitle = () => {
        return(<View style={{ flex: 1, 
                           justifyContent: 'center', 
                           alignItems: 'center'}}>
                            <h4>{ad.title}</h4>
                </View>
        );
    }

    const renderShortDescription = () => {
        if(!ad.shortDescription || ad.shortDescription === undefined || ad.shortDescription === null){
            return;
        }

        return(<View style={{ flex: 1, 
                           justifyContent: 'center', 
                           alignItems: 'center'}}>
                            <h6>{ad.shortDescription}</h6>
                </View>
        );
    }

    const renderDescription = () => {
        if(!ad.description || ad.description === undefined || ad.description.length < 3){
            return;
        }

        let editorState;
        if(!utilService.isJsonString(ad.description)){
            editorState = EditorState.createWithContent(ContentState.createFromText(ad.description))
        }
        else{
            const contentState = convertFromRaw(JSON.parse(ad.description));
            editorState = EditorState.createWithContent(contentState);
        }

        return(<View style={{ flex: 1, 
                           justifyContent: 'center', 
                           alignItems: 'center'}}>
                            <PostAdEditor
                                onRichTextEditorStateChanged={null}
                                initialEditorState={editorState}
                                isReadOnly={true} />
                </View>
        );
    }

    const renderTelephone = () => {
        if(!ad.telephone || ad.telephone === undefined || ad.telephone.length < 3){
            return;
        }
        
        return(<View style={{ flex: .9, 
            justifyContent: 'flex-start', 
            flexDirection: "row", 
            alignItems: 'center',
            marginTop:10,
            marginLeft: 10}}>
                <View style={{ justifyContent: 'flex-end', alignItems: 'flex-start', flex: .2, backgroundColor: "#DDD"}}>
                    <Text style={{fontWeight: 'bold', padding: 5}}>Telephone:</Text>                    
                </View>
                <View style={{ justifyContent: 'flex-start', flex: .7, backgroundColor: "#D8F0F3"}}>
                    <Text style={{padding: 5}}>{ad.telephone}</Text>
                </View>
        </View>);
    }

    const renderEmail = () => {
        if(!ad.email || ad.email === undefined || ad.email.length < 3){
            return;
        }
        
        return(<View style={{ flex: .9, 
            justifyContent: 'flex-start', 
            flexDirection: "row", 
            alignItems: 'center',
            marginTop:10,
            marginLeft: 10}}>
                <View style={{ justifyContent: 'flex-end', alignItems: 'flex-start', flex: .2, backgroundColor: "#DDD"}}>
                    <Text style={{fontWeight: 'bold', padding: 5}}>Email:</Text>                    
                </View>
                <View style={{ justifyContent: 'flex-start', flex: .7, backgroundColor: "#C8E1F1"}}>
                    <Text style={{padding: 5}}>{ad.email}</Text>
                </View>
        </View>);
    }

    const renderLoading = () => {
        if(!isLoading){
            return;
        }

        return(
            <div>
                <h4>Please wait</h4>
            </div>
        );
    }

    return (
        <div>
            {renderTitle()}
            {renderShortDescription()}
            {renderDescription()}
            {renderLoading()}
            {renderImage()}
            {renderTelephone()}
            {renderEmail()}
        </div>
    );
}

export default ClassifiedsAdViewer;