import React, { useState } from 'react';
import Table from 'react-bootstrap/Table'
import '../MenageRoles.css'
import AddCircle from '@material-ui/icons/AddCircle';
import Textarea from 'react-expanding-textarea'
import Button from 'react-bootstrap/Button'
import projectsService from '../../../shared/services/projectsService'
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { confirmAlert } from 'react-confirm-alert';
import authenticationService from '../../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";

function ContractorProjects() {
    const [projects, setProjects] = useState([]);
    const [isAddProject, setIsAddProject] = useState(false);
    const [newProject, setNewProject] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    const handleAddProject = () => {
        setIsAddProject(true);
    }

    const handleNewProjectChange = async(event) => {
        let newProject = event.target.value;
        setNewProject(newProject);        
    }

    const handleDescriptionChange = async(event) => {
        let projectDescription = event.target.value;
        setProjectDescription(projectDescription);        
    }

    const renderDescriptionTextArea = () => {
        return (
            <Textarea
                    className="textarea"
                    id="description-textarea"
                    align="left"
                    maxLength="3000"
                    name="subject"
                    onChange={handleDescriptionChange}
                    value={projectDescription || ''}
                    placeholder="Project description (required)" />
        );
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        let result = await projectsService.addContractorProject(newProject,projectDescription);
        console.log(result);
        if(result)
        {
            window.location.reload();  
        }
    }

    const isFormValidated = () => {
        return newProject && projectDescription;
    }

    const renderAddNewProject = () => {
        if(!isAddProject)
        {
            return;
        }

        return (
            <div>
                <input name="newProject" 
                 placeholder="New Project Name (required)" 
                 onChange={handleNewProjectChange} value={newProject || ''}/>
                {renderDescriptionTextArea()}
                <p/>
                 <div className="left">
                    <Button onClick={handleSubmit} disabled = {!isFormValidated()}>Submit</Button>
                </div>
            </div>
        )
    }

    React.useEffect(() => {

        async function fetchContractorProjects() {
            let result = await projectsService.getAllContractorProjects();
            console.log(result);
            setProjects(result);
        }
        
        fetchContractorProjects();
    }, []);

    const renderProject  = (project, index) => {
        return (
          <TableRow key={index}>
            <TableCell align="left" className="nav-item link_cursor" onClick={()=>handleProject(project.id)}>{project.title}</TableCell>
            <TableCell align="left" className="nav-item link_cursor" onClick={()=>handleProject(project.id)}>{project.description}</TableCell>
            <TableCell>
                <button className="link-btn" onClick={()=>handleDelete(project.id, project.title)}>
                    <Edit/>
                </button>
                <button className="link-btn" onClick={()=>handleDelete(project.id, project.title)}>
                    <Delete/>
                </button>
            </TableCell>
          </TableRow>
        )
    }

    const handleProject = (id) => {
        console.log("looking for " + id)
        
    }

    const handleDelete = async (id, title) => {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure to delete ' + title + ' project?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {deleteProject(id)}
              },
              {
                label: 'No',
                onClick: () => {console.log('no deletion called`')}
              }
            ]
          });
    }

    const deleteProject = async (id) => {
        console.log("deleting " + id);
        let result = await projectsService.deleteContractorProject(id);
        if(result)
        {
            window.location.reload();  
        }
    }

    const renderMainPage = (id) => {
        if(!authenticationService.isAdministrator()){
            return <Navigate to="" />;
        }
    }    

    return (
        <div className="wrapper">
            {renderMainPage()}
            <h3>Contractor Projects</h3>
            <Table striped hover size="sm">
                <TableHead>
                    <TableRow>
                        <th>Title</th>
                        <th>Description</th>
                        <th>
                        <button className="link-btn" onClick={handleAddProject}>
                            <AddCircle/>
                        </button>
                        </th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map(renderProject)}
                </TableBody>
                {renderAddNewProject()}
            </Table>
        </div>
      )
}

export default ContractorProjects