import React from 'react'
import authenticationService from '../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";

class Projects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName : '',
            email : ''
        };
    }

    componentDidMount() {
        console.log(this.props.location.state.user);
        let user = this.props.location.state.user;
        this.setState({
            firstName : user.firstName
        });
    }

    renderWelcomeHeader() {                
        return (
            <div>
                <h2>{this.state.firstName}'s projects</h2>
            </div>
        );
    }

    render() {
        if(!authenticationService.isUserLoggedIn()){
            return <Navigate to="" />;
        }

        //console.log("UserDonations token " + this.state.token);
        return (
            <section>                
            {this.renderWelcomeHeader()}
            </section>
        );
    }
}

export default Projects;