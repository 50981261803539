import React, { useState } from 'react';
import logo from './images/mdShulInside.jpg';
import './Home.css';
import useViewport from '../shared/ViewportProvider'
import { Text, StyleSheet, View } from 'react-native';
import utilService from '../shared/services/utilservice'
import authenticationService from '../shared/services/authentication.service'
import futureShul from './images/future_shul.jpg'
import { Link } from "react-router-dom";
import hebrewDayInfo from '../shared/homeComponents/HebrewDateInfo'
import 'react-day-picker/lib/style.css';
import MainPageAdsDisplay from '../pages/ads/MainPageAdsDisplay'

const SHUL = process.env.REACT_APP_SHUL;

function Home() {
    const [entity, setEntity] = useState(false);
    const [address, setAddress] = useState(false);

    React.useEffect(() => {
        utilService.markPage();
        setEntity(SHUL === 'OS' ? 'Our Shul נצח ישראל' : 'Mountaindale Shul');

        async function fetchData() {
            let address = await utilService.getAddress();;
            setAddress(address);
        }
        fetchData();
    }, []);

    return IsMobile() ? <MobileHome entity={entity} address={address}/> : <DesktopHome entity={entity} address={address}/>;
}

function IsMobile() {
    const { width } = useViewport();
    const breakpoint = 620;

    return width < breakpoint;
}

function renderWelcomeName() {
    if (!authenticationService.isUserLoggedIn()) {
        return;
    }

    return <h4>Hello <span style={{ color: "blue" }}>{authenticationService.getUserFirstName()}</span></h4>;
}

function renderWelcomeHebrew() {
    if (!authenticationService.isUserLoggedIn()) {
        return <h5>!ברוכים הבאים</h5>;
    }

    return <h5>!ברוך הבא</h5>;
}

const renderAddress = (address) => {
    if (SHUL === 'MD') {
        return (<div className="centerText">
            <div><Text>Hebrew Congregation of Mountaindale</Text></div>
            <div><Text>{address}</Text></div>
        </div>);
    }

    return (<div className="centerText">
        <div><Text>Our Shul</Text></div>
        <div><Text>{address}</Text></div>
    </div>);
}

const renderHebrewDay = () => {
    return (<div>
        <hebrewDayInfo.HebrewDayInfo format="desktop" />
    </div>);
}

const renderMemo = () => {
    return (
        <div className="centerText">
            <Text>
                Please click on the Donate link below to help the shul
                </Text>
        </div>
    );
}

const renderScheduleLink = () => {
    return <Link to="/shulSchedule">Schedule</Link>;
}

const renderStatusBar = () => {
    if (SHUL === 'MD') {
        return (<div className="centerText">
            <ul>
                <li><Link to="/contactUs">Contact Us at Mounaindale Shul</Link></li>
            </ul>
        </div>
        );
    }

    return (<div className="centerText">
        <ul>
            <li><Link to="/contactUs">Contact Us</Link></li>
        </ul>
    </div>
    );
}

function MobileHome(props) {
    const [showLogoPicture, setShowLogoPicture] = useState(false);

    const onFetchedPosts = async (areThereValidPosts) => {
        setShowLogoPicture(!areThereValidPosts);
    }

    return (
        <View>
            <div className='home'>
                <div className="centerText">
                    {renderScheduleLink()}
                    {renderHebrewDay()}
                    {renderWelcomeName()}
                    {renderWelcomeHebrew()}
                </div>
                {renderAddress(props.address)}
                <HousePic showLogoPicture={showLogoPicture} />
                <br></br>
                {renderMemo()}
                <div className="centerText">
                    <Link to="/paypal">Donate</Link>
                </div>
                <p/>
                <div className="centerText">
                    <Link to="/classifiedsMainViewer">Classifieds</Link>
                </div>
                <br></br>
                <div className="centerText">
                    <Link to="/homepagepost">Post on Main Page</Link>
                </div>
                <br></br>
                <MainPageAdsDisplay onFetchedPosts={onFetchedPosts} />
                {renderStatusBar()}
            </div>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        height: 400,
    },
});

function DesktopHome(props) {
    const [showLogoPicture, setShowLogoPicture] = useState(false);

    const onFetchedPosts = async (areThereValidPosts) => {
        setShowLogoPicture(!areThereValidPosts);
    }

    return (
        <div>
            <div className="centerText">
                {renderScheduleLink()}
            </div>
            <div className="flex-container">
                {renderHebrewDay()}
            </div>
            <div className="centerText">
                {renderWelcomeName()}
                {renderWelcomeHebrew()}
                {renderAddress(props.address)}
            </div>
            <div className="flex-container">
                <HousePic showLogoPicture={showLogoPicture} />
            </div>
            <br></br>
            {renderMemo()}
            <div className="centerText">
                <Link to="/paypal">Donate</Link>
            </div>
            <div className="centerText">
                <Link to="/classifiedsMainViewer">Classifieds</Link>
            </div>
            <div className="centerText">
                <Link to="/homepagepost">Post on Main Page</Link>
            </div>
            <br></br>
            <MainPageAdsDisplay onFetchedPosts={onFetchedPosts} />
            <br></br>
            {renderStatusBar()}
        </div>
    )
}

function HousePic({ showLogoPicture }) {
    
    const height1 = IsMobile() ? "80" : "120";
    if (SHUL === 'MD') {
        return (
            <View style={styles.container}>
                <img class="center-fit" src={logo} height={height1} alt="House" style={{ padding: 4, alignSelf: 'flex-start' }} />
            </View>);
    }

    return (
        <View style={styles.container}>
            <img class="center-fit" src={futureShul} height={height1} alt="House" style={{ padding: 4, alignSelf: 'flex-start' }} />
        </View>);
}

export default Home
