import React, { useState, useCallback } from 'react';
import dateFnsFormat from 'date-fns/format';
import '../../Home.css';
import { View, SafeAreaView, StyleSheet, TextInput, Text } from 'react-native';
import Button from 'react-bootstrap/Button';
import DayEventDlg from './DayEventDlg'
import AddCircle from '@material-ui/icons/AddCircle';
import UpdateSharp from '@material-ui/icons/UpdateOutlined';
import uuid from 'react-native-uuid';
import schedulerService from '../../../shared/services/schedulerService'
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { confirmAlert } from 'react-confirm-alert';
import helperUtil from '../../../shared/Util'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import CopyDayEventsDlg from './CopyDayEventsDlg';

export const dayScheduler = {
    DayScheduler
};

export function DayScheduler({ isDaySchedule, date }) {
    const [text, onChangeText] = React.useState("");
    const [showDayEventDlg, setShowDayEventDlg] = useState(false);
    const [eventAction, setEventAction] = useState('');
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState({});
    const [fullDayEventId, setFullDayEventId] = useState('');
    const [showCopyDayEventsDlg, setShowCopyDayEventsDlg] = useState(false);
    
    const getEvents = useCallback(async () => {
        try {
            let shulEvents = await schedulerService.getDaySchedule(formatDayDate(date));
            setEvents(shulEvents);

            let allDay = shulEvents.find((e) => e.isAllDay === true);
            if (allDay) {
                onChangeText(allDay.description)
            }
        }
        catch (e) {

        }
    }, [date])

    React.useEffect(() => {
        async function fetchData() {
            await getEvents()
        }
        fetchData();

    }, [getEvents]);

    const renderAddBtn = () => {
        if (!helperUtil.IsMobile()) {
            return <Button onClick={() => handleDayEventClick("add", null)}>
                <Text style={styles.text}>Add Event</Text>
            </Button>
        }

        return <button className="link-btn" onClick={() => handleDayEventClick("add", null)}>
            <AddCircle />
        </button>
    }

    const renderCopyEventsBtn = () => {
        if (!helperUtil.IsMobile()) {
            return <Button style={{ visibility: events.length > 0 ? 'visible' : 'hidden' }} onClick={() => handleCopyDayEventsClick()}>
                <Text style={styles.text}>Copy</Text>
            </Button>
        }

        return <button className="link-btn" style={{ visibility: events.length > 0 ? 'visible' : 'hidden' }} onClick={() => handleCopyDayEventsClick()}>
            <FileCopyRoundedIcon />
        </button>
    }

    const onDayEventDlgSubmit = async (eventInfo) => {
        setShowDayEventDlg(false);

        switch (eventInfo.action) {
            case 'add':
                await schedulerService.addDayEvent(eventInfo);
                break;
            case 'edit':
                console.log('editting event')
                console.log(eventInfo)
                await schedulerService.updateDayEvent(eventInfo)
                break;
            default:
                break;
        }

        await getEvents();
        console.log(events);
    }

    const renderUpdateBtn = () => {
        if (!helperUtil.IsMobile()) {
            return <Button onClick={() => handleFullDayEventClick()}>
                <Text style={styles.text}>Update</Text>
            </Button>
        }

        return <button className="link-btn" onClick={() => handleFullDayEventClick()}>
            <UpdateSharp />
        </button>
    }

    const renderEvent = (dayEvent) => {
        return (
            <div>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ flex: 0.22 }}>
                        <SafeAreaView>
                            <Text style={styles.eventText}>{dayEvent.title}</Text>
                        </SafeAreaView>
                    </View>
                    <View style={{ flex: 0.14 }}>
                        <SafeAreaView>
                            <Text style={styles.eventText}>{dayEvent.eventTime}</Text>
                        </SafeAreaView>
                    </View>
                    <View style={{ flex: 0.49 }}>
                        <SafeAreaView>
                            <Text style={styles.eventText}>{dayEvent.description}</Text>
                        </SafeAreaView>
                    </View>
                    <View style={{ flex: 0.05, justifyContent: "flex-end" }}>
                        <button class="link-btn" onClick={() => handleDayEventClick("edit", dayEvent)}>
                            <Edit />
                        </button>
                    </View>
                    <View style={{ flex: 0.05, justifyContent: "flex-start" }}>
                        <button class="link-btn" onClick={() => handleDeleteEvent(dayEvent.id, dayEvent.title)}>
                            <Delete />
                        </button>
                    </View>
                </View>
                <p />
            </div>
        );
    }

    const removeFullDay = (events) => {
        try {
            return events.filter((e) => e.isAllDay === false)
        }
        catch (e) {
            return null;
        }
    }

    const renderEvents = () => {
        if (!events || events.length === 0) {
            return;
        }

        let filteredEvents = removeFullDay(events);
        if (!filteredEvents || filteredEvents == null) {
            return (
                <div>
                </div>)
        }

        return (
            <div>
                {filteredEvents.map(renderEvent)}
            </div>)
    }

    const renderDaySchedule = () => {
        console.log('schedule to the day: ' + date);
        if (isDaySchedule) {
            return (
                <div>
                    {renderEvents()}
                    <View
                        style={{
                            flexDirection: "row",
                            marginBottom: 10,
                            flex: 1
                        }}>

                        <View style={{ flex: 0.65, justifyContent: "flex-end", flexDirection: "row", marginRight: 0 }}>
                            {renderAddBtn()}
                        </View>
                        <View style={{ flex: 0.15, justifyContent: "flex-end", flexDirection: "row", marginRight: 0 }}>
                            {renderCopyEventsBtn()}
                        </View>
                        <View style={{ flex: 0.15, justifyContent: "flex-end", flexDirection: "row", marginRight: 0 }}>
                            {renderUpdateBtn()}
                        </View>
                    </View>

                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 0.95 }}>
                            <SafeAreaView>
                                <TextInput
                                    multiline="true"
                                    editable
                                    style={styles.input}
                                    onChangeText={onChangeText}
                                    placeholder="Enter the day's text here"
                                    value={text}
                                />
                            </SafeAreaView>
                        </View>
                    </View>
                </div>
            )
        }

        return;
    }

    const styles = StyleSheet.create({
        input: {
            height: 300,
            marginLeft: 10,
            borderWidth: 1,
            padding: 10,
        },
        button: {
            alignItems: 'center',
            paddingVertical: 12,
            justifyContent: "flex-end",
            paddingHorizontal: 32,
            borderRadius: 4,
            elevation: 3,
            backgroundColor: 'black',
            width: 100
        },
        text: {
            fontSize: 14,
            lineHeight: 16,
            fontWeight: 'bold',
            letterSpacing: 0.2,
            color: 'white',
        },
        eventText: {
            marginLeft: 10,
            textAlign: 'left',
            backgroundColor: "#F2F4F4"
        }
    });

    const formatDayDate = (date) => {
        if (!date) {
            return '';
        }
        var lclDate = new Date(date);
        const format = 'MM-dd-yyyy';
        return dateFnsFormat(lclDate, format);
    }

    const renderDayEventDlg = () => {
        let dlgTitle;
        switch (eventAction) {
            case 'add':
                dlgTitle = 'Add event';
                break;
            default:
                break;
        }

        return <DayEventDlg showModal={showDayEventDlg}
            onClose={onDayEventDlgClose}
            dlgTitle={dlgTitle}
            onDayEventSubmit={onDayEventDlgSubmit}
            eventInfo={currentEvent} />
    }

    const renderCopyDayEventsDlg = () => {
        return <CopyDayEventsDlg showModal={showCopyDayEventsDlg}
            onClose={onCopyDayEventsDlgClose}
            date={date}
            onSubmit={onCopyDayEventsSubmit}/>
    }

    const handleDayEventClick = async (act, dayEvent) => {
        setEventAction(act);

        switch (act) {
            case 'add':
                handleEditAdd(act);
                return;
            case 'edit':
                handleEditEvent(act, dayEvent);
                return;
            default:
                break;
        }
    }

    const handleCopyDayEventsClick = async () => {
        setShowCopyDayEventsDlg(true);
    }
    
    const handleEditAdd = (act) => {
        let lclEventId;
        lclEventId = uuid.v1();
        const currEnentInfo = {
            Id: lclEventId,
            action: act,
            description: '',
            date: date
        };

        setCurrentEvent(currEnentInfo);
        setShowDayEventDlg(true);
    }

    const handleEditEvent = (act, dayEvent) => {
        dayEvent.action = act;
        console.log(dayEvent.date);
        console.log(dayEvent.eventTime);
        var editDate = new Date(dayEvent.date + ' ' + dayEvent.eventTime);

        const currEnentInfo = {
            Id: dayEvent.id,
            action: act,
            description: dayEvent.description,
            date: dayEvent.date,
            eventTime: dayEvent.eventTime,
            isAllDay: dayEvent.isAllDay,
            isCancelled: dayEvent.isCancelled,
            source: dayEvent.source,
            title: dayEvent.title,
            editDate: editDate
        };

        setCurrentEvent(currEnentInfo);
        setShowDayEventDlg(true);
    }

    const getDateTimeFormatted = () => {
        const format = 'MM/dd/yyyy HH:mm:ss';
        return dateFnsFormat(date, format);
    }

    const deleteEvent = async (id) => {
        console.log("deleting event " + id)
        let result = await schedulerService.deleteEvent(id);
        console.log(result);
        await getEvents();
    }

    const handleDeleteEvent = async (id, title) => {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure to delete ' + title + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { deleteEvent(id) }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('no deletion called`') }
                }
            ]
        });
    }

    const handleFullDayEventClick = async () => {
        let iscancelled = false;
        if (!text || text.length === 0) {
            iscancelled = true;
        }

        let allDayId = fullDayEventId;
        if (!allDayId || allDayId.length === 0) {
            allDayId = uuid.v1();
            setFullDayEventId(allDayId);
        }

        const eventInfo = {
            action: 'add',
            ID: allDayId,
            title: '',
            eventTime: '',
            eventDate: date,
            dateTimeStr: getDateTimeFormatted(),
            description: text,
            iscancelled: iscancelled,
            isallday: true
        };


        await schedulerService.addDayEvent(eventInfo);
    }

    function onDayEventDlgClose() {
        console.log('calling on close');
        setShowDayEventDlg(false);
    }

    const onCopyDayEventsSubmit = async (dates) => {
        if (!dates) {
            return;
        }

        for (let i = 0; i < dates.length; i++) {
            if(dates[i].checked){
                console.log('checked');
                console.log(dates[i]);
                dates[i].copied = await schedulerService.copyDayEvents(date, dates[i].date);
                if(!dates[i].copied){
                    dates[i].isError = true;
                }
            }
        }        
    }

    function onCopyDayEventsDlgClose() {
        setShowCopyDayEventsDlg(false);
    }

    return (
        <div>
            {renderDayEventDlg()}
            {renderCopyDayEventsDlg()}
            {renderDaySchedule()}
        </div>
    );
}

export default dayScheduler;