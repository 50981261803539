import helperUtil from 'util'
import uuid from 'react-native-uuid';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SHUL = process.env.REACT_APP_SHUL;

export const schedulerService = {
    addDayEvent,
    getDaySchedule,
    deleteEvent,
    updateDayEvent,
    updateWeekEvent,
    getWeeklyEvent,
    copyDayEvents
};

async function addDayEvent(eventInfo) {
    const data = JSON.stringify({ 
        id: eventInfo.ID,
        date: eventInfo.dateTimeStr, 
        description: eventInfo.description, 
        title: eventInfo.title, 
        eventTime: eventInfo.eventTime, 
        isCancelled: eventInfo.iscancelled,
        isAllDay: eventInfo.isallday,
        source: SHUL,
        isWeek: false,
        isHTML: false});

        let result = false;

        await fetch(SERVER_URL + 'api/ShulSchedule/AddEvent', {  method: 'POST',  body: data, headers: {
            'Content-Type': 'application/json',
          }}).then(helperUtil.handleErrors)
          .then(function(response) {      
              console.log(response);
              return response.json();
            }).then(function(data) {
              console.log(data);
              if(data.status){
                result = true;  
              }          
          }).catch(function(error) {
              console.log(error);
              result = false;
          });
    
          return result;
}

async function getDaySchedule(date) {
    console.log('about to call getDaySchedule ' + SERVER_URL + 'api/ShulSchedule/getDaySchedule/' + SHUL + '/' + date);
    let scheduleEvents = {};

    await fetch(SERVER_URL + 'api/ShulSchedule/getDaySchedule/' + SHUL + '/' + date)
    .then(helperUtil.handleErrors)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        console.log(data);
        scheduleEvents = data;        
    })
    .catch(function(error) {
        console.log(error);
    });

    return scheduleEvents;
}

async function deleteEvent(id) {
    let result = false;

    console.log('about to call getDaySchedule ' + SERVER_URL + 'api/ShulSchedule/DeleteEvent/' + id);
    
    await fetch(SERVER_URL + 'api/ShulSchedule/DeleteEvent/' + id)
    .then(helperUtil.handleErrors)
      .then(function(response) {      
          console.log(response);
          return response;
        }).then(function(data) {
          console.log(data);
          result = data;                      
      }).catch(function(error) {
          console.log(error);
          result = false;
      });

      return result;
}

async function updateDayEvent(eventInfo) {
    const data = JSON.stringify({ 
        id: eventInfo.ID,
        date: eventInfo.dateTimeStr, 
        description: eventInfo.description, 
        title: eventInfo.title, 
        eventTime: eventInfo.eventTime, 
        isCancelled: eventInfo.iscancelled,
        isAllDay: eventInfo.isallday,
        source: SHUL,
        isWeek: false,
        isHTML: false});

        console.log(data)

        let result = false;

        await fetch(SERVER_URL + 'api/ShulSchedule/UpdateEvent', {  method: 'POST',  body: data, headers: {
            'Content-Type': 'application/json',
          }}).then(helperUtil.handleErrors)
          .then(function(response) {      
              console.log(response);
              return response.json();
            }).then(function(data) {
              console.log(data);
              if(data.status){
                result = true;  
              }          
          }).catch(function(error) {
              console.log(error);
              result = false;
          });
    
          return result;
}

async function updateWeekEvent(text, startDate, endDate, isHTML) {
    console.log(text + ' ' + startDate + '  ' + endDate + ' ' + isHTML);
    const data = JSON.stringify({ 
        id: uuid.v1(),
        date: '', 
        description: text, 
        title: '', 
        eventTime: '', 
        isCancelled: false,
        isAllDay: false,
        source: SHUL,
        isWeek: true,
        isHTML: isHTML,
        startDate: startDate,
        endDate: endDate});

        console.log(data)

        let result = false;

        await fetch(SERVER_URL + 'api/ShulSchedule/ManageWeeklyEvent', {  method: 'POST',  body: data, headers: {
            'Content-Type': 'application/json',
          }}).then(helperUtil.handleErrors)
          .then(function(response) {      
              console.log(response);
              return response.json();
            }).then(function(data) {
              console.log(data);
              if(data.status){
                result = true;  
              }          
          }).catch(function(error) {
              console.log(error);
              result = false;
          });
    
          return result;
}

async function getWeeklyEvent(startDate, endDate) {
    console.log('about to call getWeeklyEvent ' + SERVER_URL + 'api/ShulSchedule/getWeeklyEvent/' + startDate + '/' + endDate + '/' + SHUL);
    let scheduleEvent = null;

    await fetch(SERVER_URL + 'api/ShulSchedule/getWeeklyEvent/' + startDate + '/' + endDate + '/' + SHUL)
    .then(function(response){
        return response.json();
    }).then(function(data) {
        console.log('getWeeklyEvent');
        console.log(data);
        scheduleEvent = data;        
    })
    .catch(function(error) {
        console.log(error);
    });

    return scheduleEvent;
}

async function copyDayEvents(date, data) {
    console.log('copyDayEvents');
    console.log('about to call api/ShulSchedule/DayEventsCopy ');

    const dataDayEventsCopy = JSON.stringify({ 
        fromDate: date,
        toDate: data,
        entity: SHUL
        });

        let result = false;

        await fetch(SERVER_URL + 'api/ShulSchedule/DayEventsCopy', {  method: 'POST',  body: dataDayEventsCopy, headers: {
            'Content-Type': 'application/json',
          }}).then(helperUtil.handleErrors)
          .then(function(response) {      
              console.log(response);
              return response.json();
            }).then(function(data) {
              console.log(data);
              if(data){
                result = true;  
              }          
          }).catch(function(error) {
              console.log(error);
              result = false;
          });
    
          return result;
}
export default schedulerService;