import { View, Text } from 'react-native';

function ClassifiedPosted() {
    const renderPosted = () => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);
        console.log('parsed');
        console.log(parsed.email);
        console.log(parsed.title);

        let isParsed = parsed && parsed.email && parsed.title;

        if(isParsed){
            return (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
                   <Text>Your classified ad titled {parsed.title} has been accepted</Text>
                   <Text>We sent an email to {parsed.email}</Text>
                   <Text>Please open the email and click on the link to confirm your submission</Text>
                   <Text>Your ad will be posted after the admin approval</Text>
                </View>
            );
        }

        return (
            <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginLeft: 10}}>
               <Text style={{textAlign: 'center', color: 'red'}}>We are having trouble processing your request</Text>
               <Text>Please try again later</Text>
            </View>
        );
    }

    return (
        <div>
            {renderPosted()}
        </div>
    );
}

export default ClassifiedPosted;