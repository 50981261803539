import React, { useState } from 'react';
import utilService from '../shared/services/utilservice'
import { Navigate } from "react-router-dom";
import authenticationService from '../shared/services/authentication.service'
import './Account.css';
import telephoneEntry from '../shared/TelephoneEntry'
import Button from 'react-bootstrap/Button';
import firstNameEntry from '../shared/FirstNameEntry'
import Geocode from "react-geocode";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import lastNameEntry from '../shared/LastNameEntry'
import { confirmAlert } from 'react-confirm-alert';

function Account() {
    const [initialFirstName, setInitialFirstName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [isFirstNameValid, setFirstNameValid] = useState(false);
    const [lastName, setLastName] = useState('');
    const [isLastNameValid, setLastNameValid] = useState(true);
    const [telephone, setTelephone] = useState('');
    const [isTelephoneValid, setTelephoneValid] = useState(true);
    const [address, setAddress] = useState(null);
    const [jewishName, setJewishName] = useState('');
    const [isJewishNameValid, setJewishNameValid] = useState(true);
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [isUpdateError, setUpdateError] = useState(false);

    React.useEffect(() => {
        async function fetchData() {
                const lclAccount = await utilService.getAccount();

                if(!lclAccount || !lclAccount.firstName){
                    setFirstNameValid(false);
                }

                console.log(lclAccount);
                setInitialFirstName(lclAccount.firstName);
                setFirstName(lclAccount.firstName);
                setLastName(lclAccount.lastName);
                setTelephone(lclAccount.telephone);
                setEmail(lclAccount.email);
                setAddress(lclAccount.address);
                setUserId(lclAccount.id);
                setJewishName(lclAccount.jewishName);
                if(lclAccount.firstName && lclAccount.firstName.length > 0){
                setFirstNameValid(true);
            }
        }
        fetchData();
        
    }, []);

    function changeFirstName(name, isValid) {
        setFirstName(name);
        setFirstNameValid(isValid);
        setUpdateError(false);
    }

    function changeLastName(name, isValid) {
        setLastName(name);
        setLastNameValid(isValid);
        setUpdateError(false);
    }

    function changeJewishName(name, isValid) {
        setJewishName(name);
        setJewishNameValid(isValid);
        setUpdateError(false);
    }

    function changeTelephone(phone, isValid) {
        setTelephone(phone);
        setTelephoneValid(isValid);
        setUpdateError(false);
    }

    const submitChanges = async () => {
        setUpdateError(false);
        const result = await utilService.modifyProfile(firstName,
            lastName,
            telephone,
            address,
            jewishName,
            userId,
            email);

        if(!result){
            setUpdateError(true);
            return;
        }

        confirmAlert({
            title: 'Confirmation',
            message: 'Your update request has been successfuly submitted',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {window.location.reload()}
              }
            ]
          });
    }

    const renderUpdateError = () => {
        if(isUpdateError){
            return <div class="left"><h3 style={{color: "red"}}><b>There was an error during submission</b></h3></div>;
        }
    }

    const handleSubmit = event => {
        setUpdateError(false);
        event.preventDefault();
        if(!isAddressValid){
            confirmAlert({
                title: 'Confirm sudmission',
                message: 'You may have an invalid address. Are you sure you want to proceed?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {submitChanges()}
                  },
                  {
                    label: 'No',
                    onClick: () => {console.log('no deletion called`')}
                  }
                ]
              });

            return;
        }

        submitChanges();
    }

    const isFormValidated = () => {
        return (isTelephoneValid && isFirstNameValid && isLastNameValid && isJewishNameValid);
      }

    const renderAccount = () => {    
        if(!authenticationService.isUserLoggedIn()){
            return <Navigate to="" />;
    }

    const handleChange = address => {
        setUpdateError(false);
        setAddress(address);
        setIsAddressValid(false);
      };

      const handleSelect = address => {
        setAddress(address);
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);
                Geocode.setApiKey('AIzaSyB-y6yWw7tzAtdgD7iRhmqrpMP1H9YH08c');
                Geocode.setLanguage("en");
                console.log('Geocode ' + latLng.lat, latLng.lng);
                Geocode.fromLatLng(latLng.lat, latLng.lng).then(
                    (response) => {
                      const address = response.results[0].formatted_address;
                      setAddress(address);
                      setIsAddressValid(true);
                    },
                    (error) => {
                      console.error(error);
                      setIsAddressValid(false);
                    }
                );
            }            
        )
        .catch(error => {
            console.error('Error', error);
            setIsAddressValid(false);
        });
        };

        return <div>
            <div className="smallLeftOffset">
                <h4>
                    Your Profile <span style={{color: "blue"}}>{initialFirstName}</span>
                </h4>
            </div>
            <form>
            <table >
                <tbody>
                <firstNameEntry.FirstNameInput onFirstNameChange={changeFirstName} name={firstName} controlsStyle="table"/>                    
                <div>
                <lastNameEntry.LastNameInput onLastNameChange={changeLastName} name={lastName}/>
                </div>
                <div>
                    <tr>
                    <td align="right">
                        <div><label>Email</label></div>
                    </td>
                    </tr>
                    <tr>
                    <td><label>{email}</label></td>
                    </tr>
                </div>
                <telephoneEntry.TelephoneInput onTelephoneChange={changeTelephone} telephone={telephone} controlsStyle="table"/>
                <div>
                    <tr>
                    <td align="right">
                        <div><label><b>Address</b></label></div>
                    </td>
                    </tr>
                </div>
                </tbody>
                </table >
                
            <div className="smallLeftOffset">
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
            <input
              {...getInputProps({
                placeholder: 'Search Location ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                    <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    >
                    <span>{suggestion.description}</span>
                    </div>
                    );
                })}
                </div>
                </div>
                )}
            </PlacesAutocomplete>
            <div className="gap-10"></div>
            </div>            
                <table>
                    <tbody>
                    <div>
                        <lastNameEntry.LastNameInput onLastNameChange={changeJewishName} name={jewishName} labelText="Jewish Name - שם יהודי" applyRegularExpression={false}/>
                    </div>
                    <tr>
                        <td>
                            {renderUpdateError()}
                        </td>
                    </tr>
                    <tr>
                    <td>
                        <div class="left">
                            <Button onClick={handleSubmit} disabled = {!isFormValidated()}>Update</Button>
                        </div>                        
                    </td>
                </tr>
                    </tbody>
                </table>

                <div>
            </div>          
        </form>     
        </div>   
    }

    return (
        <div>
            {renderAccount()}
        </div>
    )
}

export default Account