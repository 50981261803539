import React from 'react';

function TestIPN() {
    //<form target="_new" method="post" action="http://evakhovsky-001-site1.htempurl.com/api/IPN/SourceContactIPN">
    /*
    <div className="wrapper">
            <form target="_new" method="post" action="https://localhost:44383/api/IPN/SourceContactIPN">
            <input type="hidden" name="payer_email" value="sb-vyma26902967@personal.example.com"/>
            <input type="hidden" name="receiver_email" value="sb-vyma26902967@personal.example.com"/>
            <input type="hidden" name="payment_gross" value="2.25"/>
            <input type="hidden" name="transaction_subject" value="High Holidays pledge"/>
            <input type="hidden" name="first_name" value="John"/>
            <input type="hidden" name="last_name" value="Doe"/>
            <input type="hidden" name="payment_status" value="Completed"/>
            <input type="hidden" name="payment_type" value="instant"/>
            <input type="hidden" name="payment_fee" value="0.57"/>
            <input type="hidden" name="payment_date" value="09:10:15 Sep 10, 2021 PDT"/>
            <input type="hidden" name="ipn_track_id" value="66c84b270d12a"/>
            <input type="hidden" name="txn_type" value="express_checkout"/>
            <input type="hidden" name="item_name" value="High Holidays pledge"/>

            <input type="submit"/>
            </form>
        </div>
    */
    return (        
        <div className="wrapper">
            <form target="_new" method="post" action="https://localhost:44383/api/IPN/SourceContactIPN">
            <input type="hidden" name="payer_email" value="sb-vyma26902967@personal.example.com"/>
            <input type="hidden" name="receiver_email" value="sb-vyma26902967@personal.example.com"/>
            <input type="hidden" name="payment_gross" value="2.25"/>
            <input type="hidden" name="transaction_subject" value="High Holidays pledge"/>
            <input type="hidden" name="first_name" value="John"/>
            <input type="hidden" name="last_name" value="Doe"/>
            <input type="hidden" name="payment_status" value="Completed"/>
            <input type="hidden" name="payment_type" value="instant"/>
            <input type="hidden" name="payment_fee" value="0.57"/>
            <input type="hidden" name="payment_date" value="09:10:15 Sep 10, 2021 PDT"/>
            <input type="hidden" name="ipn_track_id" value="66c84b270d12a"/>
            <input type="hidden" name="txn_type" value="express_checkout"/>
            <input type="hidden" name="item_name" value="High Holidays pledge"/>

            <input type="submit"/>
            </form>
        </div>
      )
}

export default TestIPN;