import React from 'react'
import authenticationService from '../../../shared/services/authentication.service'
import { Navigate } from "react-router-dom";
import projectsService from '../../../shared/services/projectsService'
import Table from 'react-bootstrap/Table'
import '../MenageRoles.css' 
import { Link } from 'react-router-dom'

class MenageProjects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users : [],
            clickcounter: 1
        }
        
    }

    async componentDidMount() {
        this.setState({
            users : await projectsService.getAllUsers()
        });
    }

    renderUser = (user, index) => {
        return (
            <tr key={index}>
              <td style={{ textAlign: 'left' }}><Link to={{ pathname: "/menageUserProjects", state: { user: user, },}}>{user.firstName}</Link></td>
              <td style={{ textAlign: 'left' }}><Link to={{ pathname: "/menageUserProjects", state: { user: user, },}}>{user.email}</Link></td>
            </tr>
          );
    }
    showProjects(user) {
        console.log(user);
        
        
    }

    render() {
        if(!authenticationService.isAdministrator()){
            return <Navigate to="" />;
        }

        //console.log("UserDonations token " + this.state.token);
        return (
            <section>
                Menage Projects
                <Table striped hover size="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.map(this.renderUser)}
                </tbody>
            </Table>
            </section>
        );
    }
}

export default MenageProjects;