import Modal from 'react-bootstrap/Modal'
import dateFnsFormat from 'date-fns/format';
import Table from 'react-bootstrap/Table'
import {TableRow, TableCell} from '@material-ui/core';

export function ZmanimDlg({onClose, showModal, zmanim, currentDate}) {
    
    const closeModal = () => {
        console.log('closeModal');
        onClose();
    }

    const formatDayDate = (date) => {
        if(!date){
            return '';
        }
        var lclDate = new Date(date);
        const format = 'MM-dd-yyyy EEEE';
        return dateFnsFormat(lclDate, format);
    }

    return (
        <section>
            <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton style={{borderBottom:"none", borderTop:"none"}}>
            <Modal.Title>
            {formatDayDate(currentDate)}
            </Modal.Title>
            </Modal.Header>            
            <Modal.Body scrollable="true">
            <Table striped hover size="sm">
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.chatzotNightTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.chatzotNight}
                    </TableCell>                    
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.alotHaShacharTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.alotHaShachar}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.misheyakirTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.misheyakir}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.misheyakirMachmirTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.misheyakirMachmir}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.dawnTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.dawn}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.sunriseTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.sunrise}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.sofZmanShmaTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.sofZmanShma}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.sofZmanTfillaTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.sofZmanTfilla}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.chatzotTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.chatzot}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.minchaGedolaTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.minchaGedola}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.minchaKetanaTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.minchaKetana}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.plagHaMinchaTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.plagHaMincha}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.sunsetTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.sunset}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.tzeit42minTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.tzeit42min}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.tzeit50minTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.tzeit50min}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{width: '20%', borderBottom:"none", borderTop:"none"}} align="right">
                        {zmanim.tzeit72minTime}
                    </TableCell>
                    <TableCell style={{width: '80%', borderBottom:"none", borderTop:"none"}} align="left">
                        {zmanim.tzeit72min}
                    </TableCell>
                </TableRow>
            </Table>
            </Modal.Body>
            </Modal>
        </section>
    );
}

export default ZmanimDlg;